import { Button, Grid } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const ReButtonOnDialog = ({
  onClose,
  onSubmit,
  actionLabel,
  idButtonSubmit,
  dataTestId,
}) => {
  const theme = useTheme();

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        style={{
          padding: theme.spacing(2),
        }}
      >
        <Button
          variant="contained"
          style={{
            backgroundColor: theme.palette.error.main,
            color: theme.customColor.text.secondary,
            fontWeight: "bold",
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          id={idButtonSubmit || "button-submit-dialog"}
          data-testid={dataTestId || "button-submit-dialog"}
          variant="contained"
          style={{
            backgroundColor: theme.customColor.background.backdrop,
            color: theme.customColor.text.secondary,
            fontWeight: "bold",
          }}
          type="submit"
          onClick={onSubmit}
        >
          {actionLabel}
        </Button>
      </Grid>
    </>
  );
};

export default ReButtonOnDialog;

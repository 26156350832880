import React, { useEffect } from "react";

import {
  Box,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { convertNumber } from "utils/functions";
import ReListTable from "components/ReListTable";
import useLocalState from "hooks/useLocalState";
import { SentryLevel, sentSentryError } from "utils/sentry";
import ReLoadingData from "components/ReLoadingData";
import { BSON } from "realm-web";
import { getReportSalesDetail } from "sevices";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  list: {
    maxWidth: "100%",
  },
  listWider: {
    maxWidth: "400px",
  },
  container: {
    padding: theme.spacing(3),
    overflowX: "hidden",
  },
  backButton: {
    marginRight: theme.spacing(1),
    stroke: theme.customColor.background.backdrop,
    strokeWidth: 2,
  },
}));

const RowTable = ({ row, idx }) => {
  return (
    <React.Fragment key={idx}>
      <TableRow>
        <TableCell width="100%">
          <Grid container direction="row" alignItems="center">
            <Grid item xs={7}>
              <Typography>{`${row.invoiceNumber}`}</Typography>
              <Typography variant="subtitle2" style={{ fontStyle: "italic" }}>
                {moment(row.createdAt).format("DD-MM-YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={5} align="right">
              <Typography variant="subtitle2" style={{ fontWeight: "bolder" }}>
                {`${convertNumber(row.grandTotal)}`}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            padding: 0,
          }}
          colSpan={2}
        ></TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const SalesDetail = ({ onBack, selectedData, filterSelected, filterData }) => {
  const theme = useTheme();
  const classes = useStyles();
  const mobileScreen = useMediaQuery("(max-width:600px)");
  const { getLocalState, updateLocalState } = useLocalState({
    page: 1,
    rowsPerPage: 5,
    countData: 0,

    loading: false,
    listData: [],
  });

  const fetchData = async (pageNumber = 0, nPerPage = 5) => {
    updateLocalState({ loading: true, listData: [] });

    try {
      const pageN = pageNumber + 1;
      updateLocalState({ page: pageN, rowsPerPage: nPerPage });

      const filterLicense = {
        license: new BSON.ObjectId(selectedData.license),
      };

      const filterStartDate = filterData?.filterStartDate
        ? filterData.filterStartDate
        : {};

      const filterEndDate = filterData?.filterEndDate
        ? filterData.filterEndDate
        : {};

      const res = await getReportSalesDetail({
        filterLicense,
        filterStartDate,
        filterEndDate,
        nPerPage,
        pageN,
      });

      updateLocalState({
        listData: res.data,
        countData: res?.countData[0]?.count || 0,
      });
    } catch (e) {
      sentSentryError(e, SentryLevel.Error);
      console.error(e);
    }
    updateLocalState({ loading: false });
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={mobileScreen ? classes.list : classes.listWider}>
      <Grid container direction="column" className={classes.container}>
        {/* BACK BUTTON, TITLE */}
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography
              variant="h5"
              style={{
                fontWeight: "bold",
                verticalAlign: "middle",
                display: "inline-flex",
              }}
            >
              <ArrowBackIosIcon
                id="arrow-back-detail-sales"
                data-testid="arrow-back-detail-sales"
                fontSize="medium"
                color="primary"
                className={classes.backButton}
                onClick={() => {
                  onBack();
                }}
              />
              REPORT SALES DETAIL
            </Typography>
          </Grid>
        </Grid>
        <br />

        {/* PERIODE, NAME & TOTAL */}
        <Paper
          elevation={3}
          style={{
            padding: theme.spacing(2),
            marginBottom: theme.spacing(1),
            position: "relative",
          }}
        >
          <Box style={{ width: "100%", minHeight: "10px" }}>
            {filterSelected?.year?.name && (
              <Typography style={{ fontWeight: "bolder" }}>
                {`Periode : ${filterSelected?.month?.name || " "}
                  ${filterSelected.year.name}`}
              </Typography>
            )}
            <Typography variant="subtitle1" style={{ fontWeight: "bolder" }}>
              {`Name : ${selectedData.name}`}
            </Typography>
            <Typography variant="subtitle1" style={{ fontWeight: "bolder" }}>
              {`Total : ${convertNumber(selectedData.total)}`}
            </Typography>
          </Box>
        </Paper>

        {/* REPORT SALES DETAIL LIST */}
        <ReListTable
          style={{ marginBottom: theme.spacing(4) }}
          numberPage={getLocalState().page}
          countData={getLocalState().countData}
          rowsPerPage={getLocalState().rowsPerPage}
          fetchData={fetchData}
          usePagination={true}
          listContent={
            <>
              {getLocalState().loading ? (
                <ReLoadingData />
              ) : (
                <Table>
                  <TableBody>
                    {getLocalState().listData.map((row, idx) => (
                      <RowTable key={row._id} row={row} idx={idx} />
                    ))}
                  </TableBody>
                </Table>
              )}
            </>
          }
        />
      </Grid>
    </div>
  );
};

export default SalesDetail;

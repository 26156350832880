import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from "@material-ui/core";
import { forwardRef, useImperativeHandle, useState } from "react";

const ReInfoDialog = forwardRef((props, ref) => {
  const theme = useTheme();
  const [visible, setVisible] = useState(false);
  const [title, setTitle] = useState("");
  const [content, setContent] = useState(<></>);

  useImperativeHandle(ref, () => ({
    setVisible(val) {
      setVisible(val);
    },
    setTitle(val) {
      setTitle(val);
    },
    setContent(val) {
      setContent(val);
    },
  }));

  return (
    <Dialog
      id={"redialog-confirmation"}
      onClose={() => {
        setVisible(false);
      }}
      open={visible}
      maxWidth={"sm"}
      fullWidth
    >
      <DialogTitle>
        <Typography variant="h5" style={{ textAlign: "center" }}>
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          style={{
            backgroundColor: theme.palette.error.main,
            color: theme.customColor.text.secondary,
            fontWeight: "bold",
          }}
          onClick={() => {
            setVisible(false);
          }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default ReInfoDialog;

import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import ReButtonOnDialog from "./ReButtonOnDialog";

const ReDialogConfirmation = (props) => {
  const { onClose, action, open, title, onSubmit, idButtonSubmit, id } = props;

  const handleClose = () => {
    onClose(true);
  };

  const handleSubmit = () => {
    onClose(title !== undefined ? title : false);
    onSubmit && onSubmit();
  };

  return (
    <Dialog
      id={id || "redialog-confirmation"}
      onClose={handleClose}
      open={open}
      style={{
        minWidth: "fit-content",
      }}
    >
      <DialogTitle>
        Are you sure you want to {<br />} {action}{" "}
        {title !== undefined ? title : null} ?
      </DialogTitle>
      <DialogActions>
        <ReButtonOnDialog
          actionLabel={action}
          idButtonSubmit={idButtonSubmit}
          onClose={() => handleClose()}
          onSubmit={() => handleSubmit()}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ReDialogConfirmation;

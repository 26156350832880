import { useCallback, useState } from "react";

const useLocalState = (initialValue) => {
  const [localState, setLocalState] = useState(initialValue);

  const getLocalState = (key) => {
    return key ? localState[key] : localState;
  };

  const updateLocalState = useCallback((newData) => {
    setLocalState((prev) => ({
      ...prev,
      ...newData,
    }));
  }, []);

  const resetValue = useCallback(() => {
    setLocalState(initialValue);
  }, []);

  return { getLocalState, updateLocalState, resetValue };
};

export default useLocalState;

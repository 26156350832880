/* eslint-disable react-hooks/exhaustive-deps */
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

const useStyles = makeStyles((theme) => ({
  inputLabel: {
    color: "black",
    fontFamily: "Poppins-Medium",
  },
  autocomplete: {
    marginTop: theme.spacing(3),
  },
}));

const ReAutoComplete = forwardRef((props, ref) => {
  const {
    defaultValue,
    onFinish,
    className,
    disabled,
    items,
    required,
    label,
    size,
    fullWidth,
    id,
  } = props;
  const [value, setValue] = useState(defaultValue?.value || []);
  const [valueOption, setValueOption] = useState(defaultValue || []);
  const classes = useStyles();

  useImperativeHandle(ref, () => ({
    getValue() {
      return value;
    },
    getValueOption() {
      return valueOption;
    },
    setValueOption(val) {
      setValueOption(val);
    },
  }));

  useEffect(() => {
    if (onFinish) {
      onFinish(value, valueOption);
    }
  }, [value]);

  return (
    <div>
      <Autocomplete
        {...props}
        id={id || "reauto-complete"}
        getOptionLabel={(option) => option.name || ""}
        className={className ?? classes.autocomplete}
        disabled={disabled ?? false}
        options={items}
        onChange={(_, newValue) => {
          setValue(newValue?.value);
          setValueOption(newValue);
        }}
        getOptionSelected={(option, value) => option.name === value.name}
        value={valueOption}
        defaultValue={defaultValue}
        size={size ?? "medium"}
        fullWidth={fullWidth}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            required={required ?? false}
            label={<span className={classes.inputLabel}>{label}</span>}
            {...params}
          />
        )}
      />
    </div>
  );
});

export default memo(ReAutoComplete);

import { Typography } from "@material-ui/core";
import ReLayoutAdmin from "components/ReLayoutAdmin";
const Page403 = () => {
  return (
    <ReLayoutAdmin>
      <Typography>Sorry, You don't have permission</Typography>
    </ReLayoutAdmin>
  );
};

export default Page403;

import Compress from "compress.js";
import { LSname } from "localstorage";
import moment from "moment-timezone";
import { Call } from "mongoservices/setup";
import { BSON } from "realm-web";
import { SentryLevel, sentSentryError } from "./sentry";

// GET QUERY STRING FROM THE URL
export const getLocationQuery = (location, parameter) => {
  if (parameter) {
    return new URLSearchParams(location.search).get(parameter);
  }
  return location.search;
};

export const resizeImageFn = async (file) => {
  // https://stackoverflow.com/questions/61740953/reactjs-resize-image-before-upload
  const compress = new Compress();
  const resizedImage = await compress.compress([file], {
    size: 0.1, // the max size in MB, defaults to 2MB
    quality: 1, // the quality of the image, max is 1,
    maxWidth: 500, // the max width of the output image, defaults to 1920px
    maxHeight: 500, // the max height of the output image, defaults to 1920px
    resize: true, // defaults to true, set false if you do not want to resize the image width and height
  });
  const img = resizedImage[0];
  const base64str = img.data;
  const imgExt = img.ext;
  const resizedFiile = Compress.convertBase64ToFile(base64str, imgExt);
  return resizedFiile;
};

export const base64toFileImage = async (dataUrl) => {
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], `${new Date().getTime()}`, { type: "image/jpeg" });
};

export const uploadImage = async (image, outlet_id, destination) => {
  // from BO utils/fuctions
  const {
    REACT_APP_CDN_KEY: cdnApiKey,
    REACT_APP_CDN_NAME: cdnName,
    REACT_APP_CDN_PULL_ZONE: cdnPullZone,
  } = await getEnv();

  const domainBunny =
    process.env.REACT_APP_ENV === "PRODUCTIONADMIN"
      ? "sg.storage.bunnycdn.com"
      : "uk.storage.bunnycdn.com";

  if (image?.file && outlet_id && destination) {
    const imageFile = await base64toFileImage(image.url);
    try {
      const compactFile = await resizeImageFn(imageFile);
      const getName = `${image.file.name.replace(/.jpeg|.jpg|.png/g, "")}`;
      const fileName = `${getName}_${moment().unix()}`;
      const path = `${outlet_id}/${destination}/${fileName}.jpg`;

      const hitCloud = await fetch(
        `https://${domainBunny}/${cdnName}/${path}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/octet-stream",
            AccessKey: `${cdnApiKey}`,
          },
          body: compactFile,
        }
      );
      const response = await hitCloud.json();
      return {
        url: response.HttpCode === 201 ? `${cdnPullZone}/${path}` : "",
      };
    } catch (error) {
      sentSentryError(error, SentryLevel.Error);
      console.log("failed to upload image", error);
      return false;
    }
  } else {
    return false;
  }
};

export const getEnv = async () => {
  try {
    return await Call("BO", "clientGetEnv", "", {}, {}, "BUNNY");
  } catch (e) {
    sentSentryError(e, SentryLevel.Error);
    console.error(e);
  }
};

export const removeItemPage = () => {
  localStorage.removeItem(LSname.pageBefore);
  localStorage.removeItem(LSname.before);
};

export const sliceLengthString = (string = "", length = 25) => {
  return string.length < length
    ? string
    : string.slice(0, length).concat("...");
};

export const initialString = (string = "") => {
  return string
    .match(/(\b\S)?/g)
    .join("")
    .match(/(^\S|\S$)?/g)
    .join("")
    .toUpperCase();
};

export const checkAclPermission = (acl = []) => {
  let userProfile = JSON.parse(localStorage.getItem(LSname.userProfile));
  return acl.some((value) => {
    return userProfile?.acl?.includes(value);
  });
};

export const getKey = () => {
  return new BSON.ObjectId();
};

export const construcDataOption = (data) => {
  return data.map(({ _id: value, name }) => ({
    value,
    name,
  }));
};

export const sortVersion = (a, b) => {
  const versionA = a.split(".").map(Number);
  const versionB = b.split(".").map(Number);

  for (let i = 0; i < Math.max(versionA.length, versionB.length); i++) {
    const numA = versionA[i] || 0;
    const numB = versionB[i] || 0;

    if (numA !== numB) {
      return numB - numA;
    }
  }

  return 0;
};

export const isDecimal = (value) => {
  const regex = /^[-+]?\d+\.\d+$/;
  return regex.test(value);
};

export const validatePositiveNumber = (value) => {
  // regex hanya positive number
  // const regex = /^[0-9]\d*$/;

  // regex positive number + decimal
  const regex = /^[+]?(\d+(?:\.\d*)?|\.\d+)$/;
  return regex.test(value);
};

export const convertNumber = (number) => {
  const localNumber = (n) => {
    let result = n;
    if (typeof n === "string") {
      if (validatePositiveNumber(n)) {
        result = n;
      } else {
        result = +n;
      }
    }
    return result;
  };

  const locale = "id-ID";
  const num = number || 0;
  // jika ingin ada koma di belakang angka contoh 10.000,02 maka pakai code dibawah ini
  // number.toLocaleString('id-ID', { minimumFractionDigits: 2 });
  const options = {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  };

  return localNumber(num).toLocaleString(
    locale,
    isDecimal(localNumber(num)) ? options : undefined
  );
};

export const transformFormattedPrint = (row) => {
  let localRow = row;
  let stringToFormat = [
    { str: "[C]", start: '<div style="text-align: center;">', end: "</div>" },
    { str: "[X]", start: '<div style="text-align: left;">', end: "</div>" },
    { str: "[L]", start: '<div style="text-align: left;">', end: "</div>" },
    { str: "[R]", start: '<div style="text-align: right;">', end: "</div>" },
  ];

  if (localRow.includes("[L]") && localRow.includes("[R]")) {
    localRow = localRow.replace("[L]", "");
    localRow = localRow.split("[R]");
    localRow = `<div style="display: flex; justify-content: space-between;">${localRow
      .map((x) => {
        return `<div>${x}</div>`;
      })
      .join("")}</div>`;
  }

  stringToFormat.forEach((strFormat) => {
    if (localRow.includes(strFormat.str)) {
      localRow = localRow.replace(strFormat.str, "");
      localRow = `${strFormat.start}${localRow}${strFormat.end}`;
    }
  });

  return localRow;
};

export const getPrintLabel = (data) => {
  switch (data) {
    case "shift:shift_sale":
      return "SHIFT SALES";
    case "shift:shift_product":
      return "SHIFT PRODUCT";

    default:
      return data.toUpperCase();
  }
};

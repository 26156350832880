import * as Sentry from "@sentry/react";
import { LSname } from "localstorage";

export const SentryLevel = {
  Fatal: "fatal",
  Critical: "critical",
  Error: "error",
  Warning: "warning",
  Log: "log",
  Info: "info",
  Debug: "debug",
};

export const SentryInfo = () => {
  const profile = JSON.parse(localStorage.getItem(LSname.userProfile));

  const tagsInfo = {
    email: profile?.email,
  };
  const userInfo = {
    id: profile?.id,
    fullname: profile?.fullname,
    email: profile?.email,
    type: profile?.type,
  };

  return { tags: tagsInfo, user: userInfo };
};

export const sentSentryError = (
  error,
  level = SentryLevel.Info,
  dataAddBreadcrumb = null
) => {
  if (
    !process.env.REACT_APP_ENV.includes("LOCAL") &&
    !error.message.includes("is not a function")
  ) {
    if (dataAddBreadcrumb) Sentry.addBreadcrumb(dataAddBreadcrumb);

    Sentry.withScope((scope) => {
      scope.setLevel(level);
      scope.setUser(SentryInfo().user);
      scope.setTags(SentryInfo().tags);

      // The exception has the event level set by the scope (info).
      Sentry.captureException(
        error ||
          new Error(
            "This is Default Error cause Property Error Message is not passed"
          )
      );
    });
  }
};

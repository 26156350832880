import {
  Box,
  Grid,
  Paper,
  TablePagination,
  useMediaQuery,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { memo, useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(1),
  },
  paperBox: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  rootItem: {
    flexGrow: 1,
  },
  root: {
    borderBottom: 0,
  },
  selectRoot: {
    fontWeight: "bold",
    marginRight: theme.spacing(4),
    paddingTop: theme.spacing(0.3),
  },
  menuItem: {
    fontWeight: "bold",
  },
  caption: {
    color: theme.customColor.background.blackOpacity,
    fontWeight: "bold",
  },
  toolbar: {
    marginLeft: "-10px",
    fontWeight: "bold",
    "& > p:nth-of-type(2)": {
      marginRight: "-10px",
    },
  },
}));

const ReListTable = ({
  fetchData,
  countData,
  numberPage,
  rowsPerPage,
  listContent,
  style,
  usePagination,
  notUseScroll,
}) => {
  const classes = useStyles();
  const mobileScreenUp = useMediaQuery("(min-width:600px)");
  const [page, setPage] = useState(numberPage - 1);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, countData - page * rowsPerPage);

  const handleChangePage = (e, newPage) => {
    fetchData(newPage, rowsPerPage);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    fetchData(0, event.target.value);
    rowsPerPage = parseInt(event.target.value, 10);
    setPage(0);
  };

  const getStyle = () => {
    if (mobileScreenUp) {
      return { maxHeight: "52vh", overflowY: "scroll", overflowX: "hidden" };
    }
    return {};
  };

  useEffect(() => {
    if (page - numberPage >= 0) {
      setPage(0);
    }
  }, [numberPage, page]);

  return (
    <Grid container direction="column" style={style}>
      <Paper elevation={3} className={classes.paperBox}>
        <div
          className={classes.rootItem}
          style={!notUseScroll ? getStyle() : null}
        >
          <Grid container spacing={2}>
            {listContent}
          </Grid>
        </div>
        <br />
        {emptyRows > 0 && <Box></Box>}
        {usePagination && (
          <TablePagination
            component="div"
            rowsPerPageOptions={[5, 10, 15]}
            count={countData}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              MenuProps: { classes: { paper: classes.selectDropdown } },
            }}
            labelRowsPerPage={"rows page"}
            classes={{
              root: classes.root,
              menuItem: classes.menuItem,
              selectRoot: classes.selectRoot,
              toolbar: classes.toolbar,
              caption: classes.caption,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
    </Grid>
  );
};

export default memo(ReListTable);

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  ButtonBase,
  Grid,
  Paper,
  Typography,
  makeStyles,
} from "@material-ui/core";
import ReInfoDialog from "components/ReInfoDialog";
import ReLayoutAdmin from "components/ReLayoutAdmin";
import React, { useEffect, useRef, useState } from "react";
import { getListPosVersion } from "sevices";
import { checkAclPermission, sortVersion } from "utils/functions";

const useStyles = makeStyles((theme) => ({
  paperBox: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    paddingTop: theme.spacing(3),
  },
  heading: {
    fontWeight: "bold !important",
  },
  versionContainer: {
    "& .MuiAccordionSummary-content": {
      justifyContent: "space-between",
      alignItems: "center",
    },
  },
  detailVersion: {
    "& .MuiAccordionDetails-root": {
      flexDirection: "column",
    },
  },
}));

const VersionDetail = ({
  versionData,
  propVersion,
  setDetailShow,
  setDetailTitle,
  setDetailContent,
}) => {
  let constructDetailData = [];

  versionData[propVersion].forEach((detail) => {
    if (!constructDetailData.some((x) => x.user_id === detail.user_id)) {
      detail.pos = [
        {
          id: detail.posId,
          name: detail.pos_name,
        },
      ];

      constructDetailData = [
        ...constructDetailData,
        {
          user_id: detail.user_id,
          fullname: detail.fullname,
          posdata: [detail],
        },
      ];
    } else {
      let findIndexUser = constructDetailData.findIndex(
        (x) => x.user_id === detail.user_id
      );

      let currentPosData = constructDetailData[findIndexUser]["posdata"];

      let findIndexOutlet = currentPosData.findIndex(
        (y) => y.outlet === detail.outlet
      );

      if (findIndexOutlet !== -1) {
        if (
          !currentPosData[findIndexOutlet].pos.some(
            (z) => z.id === detail.posId
          )
        )
          currentPosData[findIndexOutlet]["pos"] = [
            ...currentPosData[findIndexOutlet]["pos"],
            {
              id: detail.posId,
              name: detail.pos_name,
            },
          ];
      } else {
        detail.pos = [
          {
            id: detail.posId,
            name: detail.pos_name,
          },
        ];
        currentPosData = currentPosData.push(detail);
      }
    }
  });

  const DetailContent = ({ detail }) => {
    return detail.map((data) => {
      return (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            padding: "8px 0 8px 0",
            borderBottom: "1px solid grey",
          }}
        >
          <Grid container direction="column">
            <Grid item>{`Outlet Name : ${data.outlet_name}`}</Grid>
            <Grid item>{`POS Name : ${data.pos
              .map((ps) => ps.name)
              .join(", ")}`}</Grid>
          </Grid>
        </Box>
      );
    });
  };

  return (
    <>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {constructDetailData.map((detailVer) => {
          return (
            <ButtonBase
              style={{ width: "100%", padding: 8 }}
              onClick={() => {
                setDetailShow(true);
                setDetailTitle(detailVer.fullname);
                setDetailContent(<DetailContent detail={detailVer.posdata} />);
              }}
            >
              <Typography variant="body1">{`${detailVer.fullname}`}</Typography>
            </ButtonBase>
          );
        })}
      </Box>
    </>
  );
};

const ListPosVersion = () => {
  const [versionData, setVersionData] = useState({});
  const [expanded, setExpanded] = useState("");
  const _refDetail = useRef(null);
  const classes = useStyles();

  const setDetailShow = (value) => {
    _refDetail.current?.setVisible(value);
  };

  const setDetailTitle = (title) => {
    _refDetail.current?.setTitle(title);
  };

  const setDetailContent = (content) => {
    _refDetail.current?.setContent(content);
  };

  const fetchPosVersionData = async () => {
    const posVersionData = await getListPosVersion();
    const versionMaster = {};
    posVersionData.forEach((ver) => {
      if (!versionMaster[ver.pos_version]) {
        versionMaster[ver.pos_version] = [ver];
      } else {
        versionMaster[ver.pos_version] = [
          ...versionMaster[ver.pos_version],
          ver,
        ];
      }
    });
    setVersionData(versionMaster);
  };

  useEffect(() => {
    fetchPosVersionData();
  }, []);

  return (
    <ReLayoutAdmin>
      <Paper elevation={3} className={classes.paperBox}>
        <>
          {checkAclPermission(["GEM"]) && (
            <Box>
              <Box style={{ marginBottom: 24 }}>
                <Typography>List POS Version</Typography>
              </Box>
              <Grid container direction="row" spacing={2}>
                {Object.keys(versionData)
                  .sort(sortVersion)
                  .map((version) => {
                    return (
                      <Grid key={version} item xs={12} md={4}>
                        <Accordion
                          expanded={expanded === version}
                          onChange={() => {
                            let localVersion = version;
                            if (expanded === version) {
                              localVersion = "";
                            }
                            setExpanded(localVersion);
                          }}
                        >
                          <AccordionSummary
                            className={classes.versionContainer}
                          >
                            <Typography
                              variant="body1"
                              className={classes.heading}
                            >
                              {version}
                            </Typography>
                            <Typography variant="caption">
                              {`${versionData[version].length} device`}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className={classes.detailVersion}>
                            <VersionDetail
                              propVersion={version}
                              versionData={versionData}
                              setDetailShow={setDetailShow}
                              setDetailTitle={setDetailTitle}
                              setDetailContent={setDetailContent}
                            />
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    );
                  })}
              </Grid>
            </Box>
          )}
        </>
      </Paper>
      <ReInfoDialog ref={_refDetail} />
    </ReLayoutAdmin>
  );
};

export default ListPosVersion;

const CreateStore = () => {
  let initialState = {
    sample: 1,
    sample2: 1,
    loading: false,
    countNotif: 0,
    acl: [],
  };
  let currentState = initialState;
  const listeners = new Set();

  return {
    getState: (param) => {
      if (param) {
        return currentState[param];
      }
      return currentState;
    },
    setState: (newState) => {
      currentState = {
        ...currentState,
        ...newState,
      };
      listeners.forEach((listener) => listener(currentState));
    },
    subscribe: (listener) => {
      listeners.add(listener);
      return () => listeners.delete(listener);
    },
    resetState: () => {
      currentState = initialState;
    },
  };
};

const store = CreateStore();

export default store;

import React from "react";
import { useTheme } from "@material-ui/styles";
import { Box, Grid, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    width: "100%",
    padding: theme.spacing(2),
    backgroundColor: theme.customColor.background.primary,
    position: "relative",
  },
}));

const ReLayoutAdmin = ({ children, headerContent, onMaxWidth }) => {
  const theme = useTheme();
  const classes = useStyles();
  const mobileScreenUp = useMediaQuery("(min-width:600px)");

  return (
    <Grid container direction="column">
      {headerContent ? headerContent : null}
      <Grid
        container
        style={{
          marginBottom: theme.spacing(3),
          minHeight: theme.maxHeightPageContainer,
        }}
      >
        <Box
          boxShadow={3}
          m={mobileScreenUp ? 1 : 0}
          className={classes.contentContainer}
          style={{
            [onMaxWidth ? "maxWidth" : "width"]: mobileScreenUp
              ? "95%"
              : "100%",
            marginLeft: mobileScreenUp ? "30px" : theme.spacing(0),

            minHeight: mobileScreenUp ? "80vh" : "fit-content",
          }}
        >
          {children}
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReLayoutAdmin;

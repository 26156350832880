/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
import BSON from "bson";
import { LSname } from "localstorage";
import * as Realm from "realm-web";
import store from "store/GeneralStore";
import { SentryLevel, sentSentryError } from "utils/sentry";

const reactAppEnv = `REACT_APP_ID_${process.env.REACT_APP_ENV}`;

class SetupMongo {
  constructor() {
    this.mongoClientAdmin = null;
    this.mongoClientPOS = null;
  }

  async connectMongoAdmin() {
    console.log("connectMongoAdmin");
    const response = await appCurrentUser()[0].app.currentUser?.mongoClient(
      "mongodb-atlas"
    );
    this.mongoClientAdmin = response;
    return response;
  }

  async connectMongoPOS() {
    console.log("connectMongoPOS");
    const response = await appCurrentUser()[1].app.currentUser?.mongoClient(
      "mongodb-atlas"
    );
    this.mongoClientPOS = response;
    return response;
  }

  async access(dbName, document) {
    try {
      const currentUser = appCurrentUser()[dbName === "AdminDB" ? 0 : 1];
      if (currentUser?.app.currentUser) {
        const mongoAdmin =
          this.mongoClientAdmin || (await this.connectMongoAdmin());
        const mongoPOS = this.mongoClientPOS || (await this.connectMongoPOS());
        const mongo = dbName === "AdminDB" ? mongoAdmin : mongoPOS;
        const mongoCollection = await mongo.db(dbName).collection(document);
        return mongoCollection;
      }
      localStorage.clear();
      window.location.replace("/");
    } catch (err) {
      sentSentryError(err, SentryLevel.Error);
      return { error: true, message: err };
    }
  }
}
const mongoClient = new SetupMongo();
export default mongoClient;

const getAppId = () => {
  if (
    process.env.REACT_APP_ENV === "STAGINGADMIN" ||
    process.env.REACT_APP_ENV === "LOCALSTAGING"
  )
    return `${process.env.REACT_APP_ID_TESTBO}`;

  if (
    process.env.REACT_APP_ENV === "BETAADMIN" ||
    process.env.REACT_APP_ENV === "LOCALBETA"
  )
    return `${process.env.REACT_APP_ID_BETABO}`;

  if (
    process.env.REACT_APP_ENV === "PRODUCTIONADMIN" ||
    process.env.REACT_APP_ENV === "LOCALPROD"
  )
    return `${process.env.REACT_APP_ID_PRODUCTIONBO}`;

  return `${process.env.REACT_APP_ID_DEVELOPMENTBO}`;
};

const appCurrentUser = () => {
  const credentialA = localStorage.getItem(LSname.credentialAdmin);
  const credentialB = localStorage.getItem(LSname.credentialClient);
  const appUser = [];
  if (credentialA) {
    appUser.push({
      app: new Realm.App({
        id: `${process.env[reactAppEnv]}`,
      }),
    });
  }
  if (credentialB) {
    const appId = getAppId();
    appUser.push({
      app: new Realm.App({
        id: appId,
      }),
    });
  }
  return appUser;
};

export const Call = async (
  functionType, // admin or bo
  functionName,
  method = "GET",
  body = {},
  data = {},
  filter = {}
) => {
  try {
    const byParam = functionName?.key ? functionName["value"] : null;
    const byName = functionName?.key ? functionName["key"] : null;
    const index = functionType === "ADMIN" ? 0 : 1;
    const constructObject = {
      method,
      data,
      filter,
      body,
    };
    if (appCurrentUser()[index]) {
      return appCurrentUser()[index].app.currentUser.callFunction(
        byName !== null ? byName : functionName,
        byParam !== null ? byParam : constructObject
      );
    }
  } catch (err) {
    sentSentryError(err, SentryLevel.Error);
    return { error: true, message: err };
  }
};

const getRequestToken = async () => {
  try {
    return await Call("ADMIN", "clientRequestToken", "GET");
  } catch (e) {
    sentSentryError(e, SentryLevel.Error);
    console.error(e);
  }
};

const checkRealmApp = (item) => {
  const appId = getAppId();
  const credential = item?.realm_jwt_admin
    ? LSname.credentialAdmin
    : LSname.credentialClient;

  return {
    createConnect: {
      app: new Realm.App({
        id: item?.realm_jwt_admin ? `${process.env[reactAppEnv]}` : appId,
      }),
      access: JSON.parse(localStorage.getItem(credential)),
    },
  };
};

export const fetchNotif = async () => {
  try {
    const dbNotif = await mongoClient.access("AdminDB", "admin_notification");
    const profile = JSON.parse(localStorage.getItem(LSname.userProfile));

    const ress = await dbNotif.count({
      user: { $in: [BSON.ObjectId(profile?.id)] },
      read_status: false,
      active: true,
    });

    store.setState({ countNotif: ress });
  } catch (e) {
    sentSentryError(e, SentryLevel.Error);
    console.error(e);
  }
};

export const watchNotif = async () => {
  let changeStream;
  const dbNotif = await mongoClient.access("AdminDB", "admin_notification");
  const profile = JSON.parse(localStorage.getItem(LSname.userProfile));
  console.log("start watchNotif");

  const pipeline = [
    {
      $match: {
        "fullDocument.user": BSON.ObjectId(profile?.id),
      },
    },
  ];

  if (dbNotif?.watch) {
    changeStream = dbNotif.watch(pipeline);
    try {
      for await (const change of changeStream) {
        if (
          change.operationType === "insert" ||
          change.operationType === "update"
        ) {
          fetchNotif();
        }
      }
      await changeStream.close();
      console.log("close watchNotif");
    } catch (error) {
      console.log(error);
    }
  }
};

export const loginToRealm = async (item) => {
  const { createConnect } = checkRealmApp(item);

  const credentials = Realm.Credentials.jwt(
    createConnect.access?.hit_access_admin
      ? createConnect.access.hit_access_admin
      : createConnect.access.hit_access
  );
  try {
    // Authenticate the user
    if (item?.jwt_shark) {
      const userAdmin = item.user;
      const userClient = await createConnect.app.logIn(credentials);
      await mongoClient.connectMongoPOS();

      return {
        userAdmin,
        userClient,
      };
    } else {
      const user = await createConnect.app.logIn(credentials);
      if (item?.realm_jwt_admin) {
        const { jwt_shark } = await getRequestToken();
        await mongoClient.connectMongoAdmin();

        return {
          user,
          jwt_shark,
        };
      }

      return {
        user,
      };
    }
  } catch (err) {
    sentSentryError(err, SentryLevel.Error);
    console.error("Failed to log in", err);
  }
};

export const LogoutRealm = async () => {
  if (appCurrentUser()[0]) {
    const app = appCurrentUser()[0].app;
    await app.currentUser.logOut();
  }
};

export const getValue = async () => {
  return appCurrentUser()[0]?.app;
};

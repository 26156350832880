import LayoutAdminWrapper from "components/container/LayoutAdminWrapper";
import Page403 from "components/Page403";
import { LSname } from "localstorage";
import CountryPage from "pages/admin/admin-panel/country/CountryPage";
import { lazy, Suspense, useSyncExternalStore } from "react";
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import store from "store/GeneralStore";
import ReCircularProgress from "./components/ReCircularProgress";
import ListPosVersion from "pages/admin/admin-panel/report/pos-version/ListPosVersion";
import SalesPage from "pages/admin/admin-panel/report/sales/SalesPage";

const general_site_path = "./pages/general-site";
const prelogin_path = "./pages/admin/prelogin";
const admin_panel = "./pages/admin/admin-panel";

const LoginPage = lazy(() => import(`${prelogin_path}/login/LoginPage`));

const ForgotPassword = lazy(() =>
  import(`${prelogin_path}/forgot-password/ForgotPasswordPage`)
);
const ResetPassword = lazy(() =>
  import(`${general_site_path}/ResetPasswordPage`)
);

const DashboardPage = lazy(() =>
  import(`${admin_panel}/dashboard/DashboardPage`)
);

const UserPage = lazy(() => import(`${admin_panel}/user/UserPage`));
const DetailUserPage = lazy(() =>
  import(`${admin_panel}/user/detail-user-components/DetailUserPage`)
);
const ClientPage = lazy(() => import(`${admin_panel}/client/ClientPage`));
const DetailClientPage = lazy(() =>
  import(`${admin_panel}/client/detail-client-components/DetailClientPage`)
);
const EwalletActivatePage = lazy(() =>
  import(`${admin_panel}/client/detail-client-components/EwalletActivatePage`)
);
const LicenseMasterPage = lazy(() =>
  import(`${admin_panel}/license-master/LicenseMasterPage`)
);
const AddonsMasterPage = lazy(() =>
  import(`${admin_panel}/addons-master/AddonsMasterPage`)
);
const AddAddonsPage = lazy(() =>
  import(`${admin_panel}/addons-master/AddAddonsPage`)
);
const DetailLicensePage = lazy(() =>
  import(
    `${admin_panel}/license-master/detail-license-components/DetailLicensePage`
  )
);
const NotificationPage = lazy(() => import(`${admin_panel}/NotificationPage`));

const AssigneePage = lazy(() => import(`${admin_panel}/assignee/AssigneePage`));
const ServerClientPage = lazy(() =>
  import(`${admin_panel}/server-client/ServerClientPage`)
);
const RegionPage = lazy(() => import(`${admin_panel}/region/RegionPage`));
const MessagesPage = lazy(() => import(`${admin_panel}/messages/MessagesPage`));
const ReportPage = lazy(() => import(`${admin_panel}/report/ReportPage`));
const ReportExpiredPage = lazy(() =>
  import(`${admin_panel}/report/expired-client/ExpiredPage`)
);

const HistoryTransactionPage = lazy(() =>
  import(`${admin_panel}/historytransactions/HistoryTransactionsPage`)
);
const PrintHistoryPage = lazy(() =>
  import(`${admin_panel}/printhistory/PrintHistoryPage`)
);

const PathRoutes = [
  {
    useWrapper: false,
    exact: true,
    path: "/",
    elements: (props) => <LoginPage {...props} />,
    auth: false,
    acl: "",
  },
  {
    useWrapper: false,
    exact: true,
    path: "/reset-password",
    elements: (props) => <ResetPassword {...props} />,
    auth: false,
    acl: "",
  },
  {
    useWrapper: false,
    exact: true,
    path: "/forgot-password",
    elements: (props) => <ForgotPassword {...props} />,
    auth: false,
    acl: "",
  },
  {
    useWrapper: true,
    title: "dashboard",
    exact: true,
    path: "/dashboard",
    elements: (props) => <DashboardPage {...props} />,
    auth: true,
    acl: "",
  },
  {
    useWrapper: true,
    title: "user",
    exact: true,
    path: "/user",
    elements: (props) => <UserPage {...props} />,
    auth: true,
    acl: "LSA",
  },
  {
    useWrapper: false,
    title: "detail",
    exact: true,
    path: "/user/detail",
    elements: (props) => <DetailUserPage {...props} />,
    auth: true,
    acl: "LSA",
  },
  {
    useWrapper: true,
    title: "client",
    exact: true,
    path: "/client",
    elements: (props) => <ClientPage {...props} />,
    auth: true,
    acl: "LSC",
  },
  {
    useWrapper: false,
    title: "detail",
    exact: true,
    path: "/client/detail",
    elements: (props) => <DetailClientPage {...props} />,
    auth: true,
    acl: "LSC",
  },
  {
    useWrapper: false,
    title: "detail",
    exact: true,
    path: "/client/detail-ewallet",
    elements: (props) => <EwalletActivatePage {...props} />,
    auth: true,
    acl: "LSC",
  },
  {
    useWrapper: true,
    title: "license master",
    exact: true,
    path: "/license-master",
    elements: (props) => <LicenseMasterPage {...props} />,
    auth: true,
    acl: "",
  },
  {
    useWrapper: true,
    title: "addons master",
    exact: true,
    path: "/addons-master",
    elements: (props) => <AddonsMasterPage {...props} />,
    auth: true,
    acl: "",
  },
  {
    useWrapper: true,
    title: "add addons",
    exact: true,
    path: "/add-addons",
    elements: (props) => <AddAddonsPage {...props} />,
    auth: true,
    acl: "",
  },
  {
    useWrapper: false,
    title: "detail",
    exact: true,
    path: "/license-master/detail",
    elements: (props) => <DetailLicensePage {...props} />,
    auth: true,
    acl: "",
  },
  {
    useWrapper: false,
    title: "notification",
    exact: true,
    path: "/notification",
    elements: (props) => <NotificationPage {...props} />,
    auth: true,
    acl: "",
  },
  {
    useWrapper: true,
    title: "assignee",
    exact: true,
    path: "/assignee",
    elements: (props) => <AssigneePage {...props} />,
    auth: true,
    acl: "",
  },
  {
    useWrapper: true,
    title: "server",
    exact: true,
    path: "/server-client",
    elements: (props) => <ServerClientPage {...props} />,
    auth: true,
    acl: "",
  },
  {
    useWrapper: true,
    title: "country",
    exact: true,
    path: "/country-management",
    elements: (props) => <CountryPage {...props} />,
    auth: true,
    acl: "",
  },
  {
    useWrapper: true,
    title: "region",
    exact: true,
    path: "/region",
    elements: (props) => <RegionPage {...props} />,
    auth: true,
    acl: "",
  },
  {
    useWrapper: true,
    title: "messages",
    exact: true,
    path: "/messages",
    elements: (props) => <MessagesPage {...props} />,
    auth: true,
    acl: "",
  },
  {
    useWrapper: true,
    title: "report",
    exact: true,
    path: "/report",
    elements: (props) => <ReportPage {...props} />,
    auth: true,
    acl: "LSC",
  },
  {
    useWrapper: true,
    title: "report",
    exact: true,
    path: "/report/expired",
    elements: (props) => <ReportExpiredPage {...props} />,
    auth: true,
    acl: "LSC",
  },
  {
    useWrapper: true,
    title: "report",
    exact: true,
    path: "/report/posversion",
    elements: (props) => <ListPosVersion {...props} />,
    auth: true,
    acl: "GEM",
  },
  {
    useWrapper: true,
    title: "report",
    exact: true,
    path: "/report/sales",
    elements: (props) => <SalesPage {...props} />,
    auth: true,
    acl: "GEM",
  },
  {
    useWrapper: true,
    title: "history transaction",
    exact: true,
    path: "/history-transaction",
    elements: (props) => <HistoryTransactionPage {...props} />,
    auth: true,
    acl: "GEM",
  },
  {
    useWrapper: true,
    title: "print history",
    exact: true,
    path: "/print-history",
    elements: (props) => <PrintHistoryPage {...props} />,
    auth: true,
    acl: "GEM",
    disableMaxHeight: true,
  },
];

const RequireAuth = ({ children }) => {
  const credentialA = localStorage.getItem(LSname.credentialAdmin);
  const credentialB = localStorage.getItem(LSname.credentialClient);
  const userProfile = localStorage.getItem(LSname.userProfile);

  return credentialA && credentialB && userProfile ? (
    children
  ) : (
    <Redirect to="/" replace />
  );
};

const useStore = (selector = (state) => state) =>
  useSyncExternalStore(store.subscribe, () => selector(store.getState()));

const Routes = () => {
  const userAcl = useStore((state) => state["acl"]);

  return (
    <Router>
      <Suspense fallback={<ReCircularProgress />}>
        <Switch>
          {PathRoutes.map((route) => (
            <Route
              key={route.path}
              exact={route.exact}
              path={route.path}
              render={(props) =>
                route.auth ? (
                  <RequireAuth>
                    {route.useWrapper ? (
                      <LayoutAdminWrapper
                        title={route.title}
                        disableMaxHeight={route.disableMaxHeight}
                        onMaxWidth={
                          route.title === "user" || route.title === "client"
                            ? true
                            : false
                        }
                      >
                        {/* FOR CHECK ACL */}
                        {route.acl === "" || userAcl.includes(route.acl) ? (
                          route.elements({ ...props, route })
                        ) : (
                          <Page403 />
                        )}
                      </LayoutAdminWrapper>
                    ) : (
                      route.elements({ ...props, route })
                    )}
                  </RequireAuth>
                ) : (
                  route.elements({ ...props })
                )
              }
            />
          ))}
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;

const requestNotifPermission = async () => {
  if (typeof Notification !== "undefined") {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      return true;
    } else {
      console.log("!! Notification Permission is NOT granted !!");
      return false;
    }
  }
  return false;
};

export default requestNotifPermission;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useSyncExternalStore } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, useMediaQuery, Badge } from "@material-ui/core";
import { useTheme } from "@material-ui/styles";
import { useHistory } from "react-router-dom";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { LSname } from "localstorage";
import store from "store/GeneralStore";

const useStyles = makeStyles((theme) => ({
  customBadge: {
    backgroundColor: theme.palette.secondary.main,
    fontWeight: "bolder",
  },
}));
const useStore = (selector = (state) => state) =>
  useSyncExternalStore(store.subscribe, () => selector(store.getState()));

const ReHeaderTitle = ({ title }) => {
  const mobileScreenUp = useMediaQuery("(min-width:600px)");
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const countNotif = useStore((state) => state["countNotif"]);

  const handleNotifyOpen = () => {
    localStorage.setItem(
      LSname.before,
      JSON.stringify({
        before: history.location.pathname,
      })
    );
    history.push("/notification");
  };

  return (
    <>
      <Paper
        elevation={3}
        style={{
          position: mobileScreenUp ? "absolute" : "fixed",
          backgroundColor: theme.customColor.background.backdrop,
          minWidth: mobileScreenUp ? "66.3%" : "100%",
          marginLeft: mobileScreenUp ? "230px" : "0px",
          top: "0px",
          zIndex: 5,
        }}
      >
        <h2
          style={{
            marginLeft: "50px",
            color: theme.customColor.text.secondary,
          }}
          align="left"
        >
          {title.toUpperCase()}
        </h2>
        <Badge
          badgeContent={countNotif}
          invisible={countNotif > 0 ? false : true}
          color="secondary"
          onClick={handleNotifyOpen}
          classes={{ badge: classes.customBadge }}
          max={99}
          style={{
            position: "absolute",
            top: theme.spacing(2.6),
            right: mobileScreenUp ? theme.spacing(8) : theme.spacing(2.5),
          }}
        >
          <NotificationsIcon
            fontSize="medium"
            style={{ color: theme.customColor.background.secondary }}
          />
        </Badge>
      </Paper>
    </>
  );
};

export default ReHeaderTitle;

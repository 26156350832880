import React, { useEffect } from "react";

import {
  Drawer,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import TuneIcon from "@material-ui/icons/Tune";

import { LSname } from "localstorage";
import ReLayoutAdmin from "components/ReLayoutAdmin";
import ReListTable from "components/ReListTable";
import ReLoadingData from "components/ReLoadingData";
import useLocalState from "hooks/useLocalState";
import { SentryLevel, sentSentryError } from "utils/sentry";
import SalesFilter from "./SalesFilter";
import { getReportSales } from "sevices";
import { convertNumber } from "utils/functions";
import { BSON } from "realm-web";
import SalesDetail from "./SalesDetail";

const useStyles = makeStyles((theme) => ({
  paperBox: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  wipeButton: {
    position: "absolute",
    right: -theme.spacing(2.5),
    top: -theme.spacing(1),
    color: theme.customColor.background.backdrop,
  },
  overflowText: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    width: "100%",
  },
  drawerPaperLarge: {
    width: "400px",
  },
}));

const RowTable = ({ row, idx, onClick }) => {
  const classes = useStyles();

  return (
    <React.Fragment key={idx}>
      <TableRow>
        <TableCell
          width="100%"
          onClick={() => {
            onClick();
          }}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item xs={7}>
              <Typography
                className={classes.overflowText}
              >{`${row.name}`}</Typography>
              <Typography
                variant="subtitle2"
                style={{ fontStyle: "italic" }}
              >{`${row.phone}`}</Typography>
            </Grid>
            <Grid item xs={5} align="right">
              <Typography variant="subtitle2" style={{ fontWeight: "bolder" }}>
                {`${convertNumber(row.total)}`}
              </Typography>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            padding: 0,
          }}
          colSpan={2}
        ></TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const getFilterExclude = () => {
  const getExclude = JSON.parse(localStorage.getItem(LSname.dataExclude));

  let filterExcludeIds = {};
  if (getExclude) {
    const localExclude = getExclude.filter((x) => x.fullname);
    const getExcludeIds = localExclude.map(function (item) {
      return new BSON.ObjectId(item._id);
    });

    filterExcludeIds = { license: { $nin: getExcludeIds } };
  }
  return filterExcludeIds;
};

const SalesPage = () => {
  const theme = useTheme();
  const classes = useStyles();
  const mobileScreenUp = useMediaQuery("(min-width:600px)");
  const profile = JSON.parse(localStorage.getItem(LSname.userProfile));
  const { getLocalState, updateLocalState } = useLocalState({
    page: 1,
    rowsPerPage: 5,
    countData: 0,
    grandTotal: 0,

    loading: false,
    listData: [],
    filterData: null,
    filterSelected: null,
    drawerFilter: false,

    selectedData: null,
    drawerDetail: false,
  });

  const fetchData = async (pageNumber = 0, nPerPage = 5) => {
    updateLocalState({ loading: true, listData: [] });

    try {
      const pageN = pageNumber + 1;
      updateLocalState({ page: pageN, rowsPerPage: nPerPage });

      const filterStartDate = getLocalState().filterData?.filterStartDate
        ? getLocalState().filterData.filterStartDate
        : {};

      const filterEndDate = getLocalState().filterData?.filterEndDate
        ? getLocalState().filterData.filterEndDate
        : {};

      const filterExcludeIds = getLocalState().filterData?.filterExcludeIds
        ? getLocalState().filterData.filterExcludeIds
        : getFilterExclude();

      const res = await getReportSales({
        filterStartDate,
        filterEndDate,
        filterExcludeIds,
        nPerPage,
        pageN,
      });

      updateLocalState({
        listData: res.data,
        grandTotal: res?.totalData[0]?.grandTotal || 0,
        countData: res?.countData[0]?.count || 0,
      });
    } catch (e) {
      sentSentryError(e, SentryLevel.Error);
      console.error(e);
    }
    updateLocalState({ loading: false });
  };

  const handleSetFilter = ({ year, month, exclude }) => {
    let filterStartDate = {};
    let filterEndDate = {};
    if (year && year?.value) {
      const getMonth = month && (month?.value || month?.value === 0);
      const startDate = new Date(year.value, getMonth ? month.value : 0, 1);
      const endDate = new Date(year.value, getMonth ? month.value + 1 : 12, 0);

      filterStartDate = { createdAt: { $gte: startDate } };
      filterEndDate = { createdAt: { $lte: endDate } };
    }

    const localExclude = exclude.filter((x) => x.fullname);
    let filterExcludeIds = {};
    if (localExclude.length > 0) {
      const getExcludeIds = localExclude.map(function (item) {
        return new BSON.ObjectId(item._id);
      });

      filterExcludeIds = { license: { $nin: getExcludeIds } };
    }

    updateLocalState({
      filterData: { filterStartDate, filterEndDate, filterExcludeIds },
    });
  };

  useEffect(() => {
    if (getLocalState().filterData !== null) {
      const timeoutId = setTimeout(() => {
        updateLocalState({ listData: [] });
        fetchData();
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLocalState().filterData]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ReLayoutAdmin>
      {profile.type === "superadmin" && (
        <>
          {/* GRAND TOTAL & FILTER BUTTON */}
          <Paper elevation={3} className={classes.paperBox}>
            <Grid
              container
              spacing={2}
              direction="row"
              justifyContent="space-evenly"
            >
              <Grid item xs={9} align="left">
                <Typography>Grand Total</Typography>
                <Typography variant="h6" style={{ fontWeight: "bold" }}>
                  {`${convertNumber(getLocalState().grandTotal)}`}
                </Typography>
              </Grid>
              <Grid
                item
                xs={2}
                align={mobileScreenUp ? "right" : "center"}
                style={{ marginTop: -theme.spacing(1.5) }}
              >
                <IconButton
                  data-testid="button-open-filter"
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={() => {
                    updateLocalState({ drawerFilter: true });
                  }}
                >
                  <TuneIcon fontSize="large" color="primary" />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>

          {/* REPORT SALES LIST */}
          <ReListTable
            style={{ marginBottom: theme.spacing(4) }}
            numberPage={getLocalState().page}
            countData={getLocalState().countData}
            rowsPerPage={getLocalState().rowsPerPage}
            fetchData={fetchData}
            usePagination={true}
            listContent={
              <>
                {getLocalState().loading ? (
                  <ReLoadingData />
                ) : (
                  <Table>
                    <TableBody>
                      {getLocalState().listData.map((row, idx) => (
                        <RowTable
                          onClick={() => {
                            updateLocalState({
                              selectedData: row,
                              drawerDetail: true,
                            });
                          }}
                          key={row._id}
                          row={row}
                          idx={idx}
                        />
                      ))}
                    </TableBody>
                  </Table>
                )}
              </>
            }
          />

          {/* DRAWER FILTER */}
          <Drawer
            anchor={"right"}
            open={getLocalState().drawerFilter}
            onClose={() => {
              updateLocalState({ drawerFilter: false });
            }}
            classes={{
              paper: mobileScreenUp
                ? classes.drawerPaperLarge
                : classes.drawerPaper,
            }}
          >
            <SalesFilter
              onBack={() => updateLocalState({ drawerFilter: false })}
              handleSetFilter={(val) => {
                handleSetFilter(val);
                updateLocalState({ filterSelected: val });
              }}
              filterData={getLocalState().filterSelected}
            />
          </Drawer>

          {/* DRAWER DETAIL SALES */}
          <Drawer
            anchor={"right"}
            open={getLocalState().drawerDetail}
            onClose={() => {
              updateLocalState({ drawerDetail: false });
            }}
            classes={{
              paper: mobileScreenUp
                ? classes.drawerPaperLarge
                : classes.drawerPaper,
            }}
          >
            <SalesDetail
              onBack={() => updateLocalState({ drawerDetail: false })}
              selectedData={getLocalState().selectedData}
              filterSelected={getLocalState().filterSelected}
              filterData={getLocalState().filterData}
            />
          </Drawer>
        </>
      )}
    </ReLayoutAdmin>
  );
};

export default SalesPage;

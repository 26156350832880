import React, { memo } from "react";
import { Backdrop, CircularProgress, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  backdrop: {
    color: "#fff",
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const ReLoadingData = () => {
  const mobileScreen = useMediaQuery("(max-width:600px)");
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={true}>
      <CircularProgress
        color="inherit"
        thickness={2}
        size={mobileScreen ? 50 : 75}
        disableShrink
      />
    </Backdrop>
  );
};

export default memo(ReLoadingData);

import { makeStyles } from "@material-ui/core/styles";

export const formPaymentStyles = makeStyles((theme) => ({
  formPayment: {
    border: `1px solid ${theme.customColor.background.dimGray}`,
    borderRadius: theme.spacing(1),
    padding: theme.spacing(0.7),
    marginBottom: theme.spacing(1),
    "& .MuiFormControlLabel-label": {
      fontWeight: "bold",
    },
    "& .MuiRadio-root.Mui-checked": {
      color: theme.customColor.text.secondary,
    },
  },
  boxList: {
    border: `4px solid ${theme.customColor.background.errorOpacity}`,
    borderRadius: theme.spacing(1),
    position: "relative",
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
    fontWeight: "bold",
  },
}));

export const detailClientStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    overflowX: "hidden",
    minHeight: "calc(100vh - 50px)",
    maxHeight: "100%",
    overflowY: "hidden",
    backgroundColor: theme.customColor.background.secondary,
    position: "relative",
  },
  containerWider: {
    padding: theme.spacing(3),
    overflowX: "hidden",
    display: "inline-flex",
    marginLeft: "auto",
    marginRight: "auto",
    width: "77%",
    minHeight: "calc(100vh - 50px)",
    maxHeight: "100%",
    overflowY: "hidden",
    backgroundColor: theme.customColor.background.secondary,
    position: "relative",
  },
  backButton: {
    marginRight: theme.spacing(1),
    stroke: theme.customColor.background.backdrop,
    strokeWidth: 2,
  },
}));

export const preLoginStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: theme.customColor.background.secondary,
    margin: 0,
    padding: 0,
    height: "100vh",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  leftContent: {
    backgroundColor: theme.customColor.background.primary,
    height: "100%",
  },
  rightContent: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "left",
  },
}));

export const formContainerStyles = makeStyles((theme) => ({
  list: {
    maxWidth: "100%",
  },
  listWider: {
    maxWidth: "400px",
  },
  container: {
    padding: theme.spacing(3),
    overflowX: "hidden",
  },
  backButton: {
    marginRight: theme.spacing(1),
    stroke: theme.customColor.background.backdrop,
    strokeWidth: 2,
  },
  addCloseButton: {
    marginRight: theme.spacing(1),
    color: theme.customColor.background.backdrop,
    strokeWidth: 1,
  },
  IconButton: {
    marginTop: theme.spacing(3),
  },
}));

export const historyLayoutStyles = makeStyles((theme) => ({
  paperBox: {
    boxShadow: "none",
  },
  leftText: {
    fontWeight: "bold",
    "@media (max-width:600px)": {
      width: "180px",
      whiteSpace: "nowrap",
      overflowX: "scroll",
    },
  },
  rightText: {
    fontWeight: "bolder",
    position: "absolute",
    right: 0,
    color: theme.customColor.background.backdrop,
  },
}));

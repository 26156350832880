/* eslint-disable import/prefer-default-export */

export const LSname = {
  credentialAdmin: "credsAdmin",
  credentialClient: "credsClient",
  userProfile: "userProfile",
  detailAdmin: "detailAdmin",
  detailClient: "detailClient",
  timestamp: "timestamp",
  notifToken: "notifToken",
  fromNotify: "fromNotify",
  register: "register",
  pageBefore: "pageBefore",
  before: "before",
  dataFilterClient: "dataFilterClient",
  dataCountry: "dataCountry",
  appServiceVersion: "appServiceVersion",
  dataExclude: "dataExclude",
};

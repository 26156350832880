import {
    Drawer,
    Grid,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableRow,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import { makeStyles } from "@material-ui/styles";
import ReLayoutAdmin from "components/ReLayoutAdmin";
import ReListTable from "components/ReListTable";
import ReLoadingData from "components/ReLoadingData";
import mongoClient, { Call } from "mongoservices/setup";
import React, { useEffect, useState } from "react";
import { SentryLevel, sentSentryError } from "utils/sentry";
import EditCountry from "./EditCountry";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "100%",
  },
  drawerPaperLarge: {
    width: "400px",
  },
}));

const RowTable = ({ row, idx, onClick }) => {
  return (
    <React.Fragment key={idx}>
      <TableRow>
        <TableCell width="70%">
          <Typography>{`${row.name}`}</Typography>
          <Typography> {`Tax ${row.tax}`}</Typography>
          {row?.generalConfig &&
            row.generalConfig.map(({ label, value, key }) => (
              <Typography key={key}>{`${label} ${value}`}</Typography>
            ))}
        </TableCell>
        <TableCell>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            <IconButton
              data-testid={`button-edit-${idx}`}
              onClick={() => {
                onClick();
              }}
              size="small"
            >
              <EditIcon />
            </IconButton>
          </Grid>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            padding: 0,
          }}
          colSpan={2}
        ></TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const CountryPage = () => {
  const classes = useStyles();
  const mobileScreenUp = useMediaQuery("(min-width:600px)");

  const [dataRegion, setdataRegion] = useState([]);
  const [countData, setCountData] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedDataRow, setSelectedData] = useState(null);

  const fetchCountry = async () => {
    setdataRegion([]);
    setLoading(true);
    try {
      const dbCountry = await mongoClient.access(
        "CORE_DB",
        "master_reg_country"
      );
      const defaultCountryCode = await Call("ADMIN", "clientCountry");

      let [region] = await dbCountry.aggregate([
        {
          $facet: {
            data: [
              {
                $match: { code: { $in: defaultCountryCode } },
              },
              { $project: { name: 1, tax: 1, generalConfig: 1 } },
              { $sort: { _id: 1 } },
            ],
            totalData: [
              {
                $match: { code: { $in: defaultCountryCode } },
              },
              { $count: "count" },
            ],
          },
        },
      ]);

      setCountData(region?.totalData[0]?.count || 0);
      setdataRegion(region?.data);
    } catch (e) {
      sentSentryError(e, SentryLevel.Error);
      console.error(e);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchCountry();
  }, []);

  return (
    <ReLayoutAdmin>
      <>
        {loading ? (
          <ReLoadingData />
        ) : (
          dataRegion.length > 0 && (
            <ReListTable
              countData={countData}
              fetchData={fetchCountry}
              usePagination={false}
              notUseScroll
              listContent={
                <>
                  <Table className={classes.table}>
                    <TableBody>
                      {dataRegion.map((row, idx) => (
                        <RowTable
                          onClick={() => {
                            setShowDrawer(true);
                            setSelectedData(row);
                          }}
                          key={row._id}
                          row={row}
                          idx={idx}
                        />
                      ))}
                    </TableBody>
                  </Table>
                </>
              }
            />
          )
        )}
        <Drawer
          anchor={"right"}
          open={showDrawer}
          onClose={() => setShowDrawer(false)}
          classes={{
            paper: mobileScreenUp
              ? classes.drawerPaperLarge
              : classes.drawerPaper,
          }}
        >
          <EditCountry
            onBack={() => setShowDrawer(false)}
            selectedData={selectedDataRow}
            onFetchData={fetchCountry}
          />
        </Drawer>
      </>
    </ReLayoutAdmin>
  );
};

export default CountryPage;

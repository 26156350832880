import { LSname } from "localstorage";
import mongoClient, { Call } from "mongoservices/setup";
import { BSON } from "realm-web";
import { SentryLevel, sentSentryError } from "utils/sentry";

export const getUsers = async (payload) => {
  const dbUser = await mongoClient.access("AdminDB", "user_admin");
  const { filter, pageN, nPerPage } = payload;

  const [ress] = await dbUser.aggregate([
    {
      $facet: {
        data: [
          { $match: filter },
          {
            $project: {
              fullname: 1,
              phone: 1,
              active: 1,
              email: 1,
              username: 1,
              type: 1,
              acl: 1,
            },
          },
          { $sort: { fullname: 1 } },
          { $skip: pageN > 0 ? (pageN - 1) * nPerPage : 0 },
          { $limit: nPerPage },
        ],
        countData: [{ $match: filter }, { $count: "count" }],
      },
    },
  ]);

  return ress;
};

export const getClients = async (payload) => {
  const dbClient = await mongoClient.access("CORE_DB", "user");
  const { filter, pageN, sortData, nPerPage, profile } = payload;
  const filterAssignee =
    profile.type === "admin"
      ? { type: "owner", assignee: { $in: [BSON.ObjectId(profile.id)] } }
      : { type: "owner" };

  const [ress] = await dbClient.aggregate([
    {
      $facet: {
        data: [
          { $match: { ...filterAssignee, ...filter } || filterAssignee },
          {
            $lookup: {
              from: "user_license_device",
              let: { license_user: "$license" },
              pipeline: [
                {
                  $match: {
                    $expr: { $eq: ["$license", "$$license_user"] },
                    active: true,
                  },
                },
                { $sort: { expired: 1 } },
                {
                  $project: { expired: 1 },
                },
              ],
              as: "list_license",
            },
          },
          {
            $addFields: {
              fullnameLowerCase: { $toLower: "$fullname" },
              nearExpired: { $first: "$list_license" },
            },
          },
          {
            $addFields: {
              nearExpiredDate: "$nearExpired.expired",
            },
          },
          {
            $project: {
              _id: 1,
              fullname: 1,
              type: 1,
              fullnameLowerCase: 1,
              assignee: 1,
              user_id: 1,
              list_license: 1,
              createdBy: 1,
              nearExpiredDate: 1,
              active: 1,
            },
          },
          { $sort: sortData || { _id: 1 } },
          { $skip: pageN > 0 ? (pageN - 1) * nPerPage : 0 },
          { $limit: nPerPage },
        ],
        countData: [
          { $match: { ...filterAssignee, ...filter } || filterAssignee },
          { $count: "count" },
        ],
      },
    },
  ]);

  return ress;
};

export const getAssigneeByIds = async ({ assigneeIds }) => {
  const Assignee = await mongoClient.access("AdminDB", "user_admin");

  const ress = await Assignee.aggregate([
    {
      $match: {
        $and: [
          { _id: { $in: assigneeIds.map((v) => BSON.ObjectId(v.toString())) } },
          { client_id: { $exists: true } },
          { client_id: { $ne: [] } },
        ],
      },
    },
    { $unwind: "$client_id" },
    {
      $group: {
        _id: "$client_id",
        fullname: { $push: "$fullname" },
      },
    },
    { $project: { _id: 1, fullname: 1 } },
  ]);
  return ress;
};

export const getRegions = async (filter = {}) => {
  const dbRegion = await mongoClient.access("AdminDB", "region");
  const ress = await dbRegion.find(filter, {
    projection: { region_name: 1, clients: 1 },
  });
  return ress;
};

export const getDetailClient = async (id_user) => {
  const dbClient = await mongoClient.access("CORE_DB", "user");

  const [ress] = await dbClient.aggregate([
    { $match: { _id: id_user } },
    {
      $lookup: {
        from: "user_license_device",
        let: { license_user: "$license" },
        pipeline: [
          {
            $match: {
              $expr: { $eq: ["$license", "$$license_user"] },
              active: true,
            },
          },
          {
            $project: {
              expired: 1,
              payment: 1,
              active: 1,
              user_id: 1,
              license: 1,
              license_label: 1,
              outlet: 1,
              pos_id: 1,
              createdBy: 1,
            },
          },
          { $sort: { expired: 1 } },
        ],
        as: "list_license",
      },
    },
    {
      $lookup: {
        from: "user_business",
        let: { license: "$license" },
        pipeline: [
          { $match: { $expr: { $eq: ["$license", "$$license"] } } },
          { $project: { name: 1 } },
        ],
        as: "list_business",
      },
    },
    {
      $lookup: {
        from: "outlet",
        let: { license_user: "$license" },
        pipeline: [
          {
            $match: {
              $expr: { $eq: ["$license", "$$license_user"] },
            },
          },
          {
            $lookup: {
              from: "user_business",
              localField: "business_id",
              foreignField: "_id",
              as: "businessName",
            },
          },
          {
            $unwind: {
              path: "$businessName",
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $lookup: {
              from: "pos_ids",
              localField: "_id",
              foreignField: "outlet",
              as: "posId",
            },
          },
          {
            $project: {
              user_id: 1,
              license: 1,
              outlet: 1,
              name: 1,
              country: 1,
              province: 1,
              city: 1,
              outlet_email: 1,
              email_verified: 1,
              xendit_active: 1,
              business_id: 1,
              createdAt: 1,
              posId: { name: 1 },
              businessName: "$businessName.name",
            },
          },
        ],

        as: "list_outlet",
      },
    },
    {
      $lookup: {
        from: "user_license_payment",
        let: { license_user: "$license" },
        pipeline: [
          {
            $match: {
              $expr: { $eq: ["$license", "$$license_user"] },
            },
          },
          {
            $project: { createdAt: 1, status: 1, xendit_invoice_url: 1 },
          },
          { $sort: { createdAt: 1 } },
        ],
        as: "list_license_payment",
      },
    },
    {
      $addFields: {
        fullnameLowerCase: { $toLower: "$fullname" },
        nearExpired: { $first: "$list_license" },
      },
    },
    {
      $project: {
        user_id: 1,
        username: 1,
        fullname: 1,
        email: 1,
        phone: 1,
        type: 1,
        license: 1,
        active: 1,
        createdBy: 1,
        password: 1,
        assignee: 1,
        identity_url: 1,
        selfie_image: 1,
        nik: 1,
        media_social_link: 1,
        list_license: 1,
        list_business: 1,
        list_outlet: 1,
        list_license_payment: 1,
        nearExpired: { expired: 1 },
      },
    },
  ]);

  return ress;
};

export const getAssigneeGroupbyIds = async (assigneeIds) => {
  const Assignee = await mongoClient.access("AdminDB", "user_admin");

  const ress = await Assignee.aggregate([
    { $match: { _id: { $in: assigneeIds } } },
    { $group: { _id: 0, fullname: { $push: "$fullname" } } },
    { $project: { _id: 0, fullname: 1 } },
  ]);

  return ress;
};

export const getAssigneeById = async (_id) => {
  const dbAssignee = await mongoClient.access("AdminDB", "user_admin");

  return dbAssignee.findOne(
    {
      _id: BSON.ObjectId(_id),
    },
    { projection: { fullname: 1 } }
  );
};

export const getRegionByClients = async (clientIds) => {
  const Region = await mongoClient.access("AdminDB", "region");

  return await Region.findOne(
    {
      clients: { $in: clientIds },
    },
    { projection: { region_name: 1 } }
  );
};

export const postHistoryClient = async (action, client_id) => {
  const dbHistoryClient = await mongoClient.access("AdminDB", "history_client");
  const profile = JSON.parse(localStorage.getItem(LSname.userProfile));
  const _id = new BSON.ObjectId();

  try {
    await dbHistoryClient.insertOne({
      _id,
      _partition: "",
      __v: BSON.Long.fromNumber(0),
      action,
      client_id: BSON.ObjectId(client_id),
      createdBy: BSON.ObjectId(profile?.id),
      updatedBy: BSON.ObjectId(profile?.id),
      createdAt: new Date(),
      updatedAt: new Date(),
    });
    return true;
  } catch (error) {
    sentSentryError(error, SentryLevel.Error);
    console.error(error);
  }
};

export const postHistoryMasterLicense = async (data, id) => {
  const profile = JSON.parse(localStorage.getItem(LSname.userProfile));
  const dbHistoryMaster = await mongoClient.access(
    "AdminDB",
    "history_license_master"
  );

  // logging history_license_master - update
  const newHistoryMasterId = new BSON.ObjectId();
  // save to history_license_master
  await dbHistoryMaster.insertOne({
    _id: newHistoryMasterId,
    _partition: "",
    __v: BSON.Long.fromNumber(0),
    action: data,
    license_master_id: BSON.ObjectId(id.toString()),
    createdBy: BSON.ObjectId(profile?.id),
    updatedBy: BSON.ObjectId(profile?.id),
    createdAt: new Date(),
    updatedAt: new Date(),
  });
};

export const saveDataCountry = async () => {
  try {
    const dbCountry = await mongoClient.access("CORE_DB", "master_reg_country");
    const defaultCountryCode = await Call("ADMIN", "clientCountry");

    const res = await dbCountry.find(
      { code: { $in: defaultCountryCode || [] } },
      { projection: { code: 1, name: 1 } }
    );

    const regionOption = res.map(
      ({ code: value, _id: countryId, ...rest }) => ({
        value,
        countryId,
        ...rest,
      })
    );

    localStorage.setItem(LSname.dataCountry, JSON.stringify(regionOption));
    return regionOption;
  } catch (error) {
    sentSentryError(error, SentryLevel.Error);
    console.error(error);
  }
};

export const getMasterLicense = async (payload) => {
  const { countryId, pageN, nPerPage } = payload;
  const dbLicenseMaster = await mongoClient.access("CORE_DB", "master_license");

  const [ress] = await dbLicenseMaster.aggregate([
    {
      $facet: {
        data: [
          { $match: { country_id: BSON.ObjectId(countryId.toString()) } },
          {
            $lookup: {
              from: "master_reg_country",
              let: { country_id: "$country_id" },
              pipeline: [
                { $match: { $expr: { $eq: ["$_id", "$$country_id"] } } },
                { $project: { _id: 0, name: 1 } },
              ],
              as: "country",
            },
          },
          {
            $unwind: {
              path: "$country",
              preserveNullAndEmptyArrays: true,
            },
          },
          {
            $project: {
              _id: 1,
              name: 1,
              country_id: 1,
              createdBy: 1,
              priceLevel: 1,
              discount: 1,
              active: 1,
              country: 1,
            },
          },
          { $sort: { serial: 1 } },
          { $skip: pageN > 0 ? (pageN - 1) * nPerPage : 0 },
          { $limit: nPerPage },
        ],
        countData: [
          { $match: { country_id: BSON.ObjectId(countryId.toString()) } },
          { $count: "count" },
        ],
      },
    },
  ]);

  return ress;
};

export const getMasterLicenseDetail = async (masterLicenseId) => {
  const dbLicenseMaster = await mongoClient.access("CORE_DB", "master_license");

  const [ress] = await dbLicenseMaster.aggregate([
    { $match: { _id: BSON.ObjectId(masterLicenseId.toString()) } },
    {
      $lookup: {
        from: "master_license_price_level",
        let: { priceLevel: "$priceLevel" },
        pipeline: [
          { $match: { $expr: { $in: ["$_id", "$$priceLevel"] } } },
          { $project: { _id: 0, updatedBy: 0, updatedAt: 0 } },
        ],
        as: "priceLevelDetail",
      },
    },
    {
      $lookup: {
        from: "master_license_discount",
        let: { discount: "$discount" },
        pipeline: [
          { $match: { $expr: { $in: ["$_id", "$$discount"] } } },
          { $project: { _id: 0, updatedBy: 0, updatedAt: 0 } },
        ],
        as: "discountDetail",
      },
    },
    {
      $lookup: {
        from: "user_license_payment",
        let: { id: "$_id" },
        pipeline: [
          { $match: { $expr: { $eq: ["$masterLicenseId", "$$id"] } } },
          { $project: { _id: 1 } },
        ],
        as: "userLicense",
      },
    },
    {
      $addFields: {
        countUserLicense: { $size: "$userLicense" },
      },
    },
    {
      $project: {
        __v: 0,
        _partition: 0,
        createdAt: 0,
        updatedAt: 0,
        updatedBy: 0,
        userLicense: 0,
      },
    },
  ]);

  return ress;
};

export const getClientsWithLicense = async (payload) => {
  const {
    rangeDate,
    filterAssignee,
    filterRegion,
    filterCountry,
    sortData,
    nPerPage,
    pageN,
  } = payload;
  const dbLicense = await mongoClient.access("CORE_DB", "user_license_device");

  const pipeline = [
    {
      $match: { expired: { $lt: rangeDate }, outlet: { $ne: null } },
    },
    {
      $lookup: {
        from: "user",
        let: { user_id: "$user_id" },
        pipeline: [
          {
            $match: {
              $expr: {
                $eq: ["$_id", "$$user_id"],
              },
              ...filterAssignee,
              ...filterRegion,
            },
          },
        ],
        as: "user",
      },
    },
    {
      $unwind: {
        path: "$user",
      },
    },
    {
      $lookup: {
        from: "user_business",
        let: { license: "$license" },
        pipeline: [
          { $match: { $expr: { $eq: ["$license", "$$license"] } } },
          { $project: { name: 1 } },
        ],
        as: "listBusiness",
      },
    },
    {
      $lookup: {
        from: "outlet",
        let: { outlet: "$outlet" },
        pipeline: [
          {
            $match: {
              active: true,
              _id: "$$outlet",
              ...filterCountry,
            },
          },
          {
            $lookup: {
              from: "master_reg_country",
              localField: "country",
              foreignField: "_id",
              as: "country",
            },
          },
        ],
        as: "outlets",
      },
    },

    {
      $unwind: {
        path: "$outlets",
      },
    },
  ];

  const [ress] = await dbLicense.aggregate([
    {
      $facet: {
        data: [
          ...pipeline,
          {
            $addFields: {
              country: { $first: "$outlets.country" },
            },
          },
          {
            $addFields: {
              fullnameLowerCase: { $toLower: "$user.fullname" },
            },
          },
          {
            $project: {
              _id: 1,
              license_label: 1,
              user: { fullname: 1, _id: 1 },
              expired: 1,
              country: { name: 1 },
              outlets: 1,
              listBusiness: { name: 1 },
              fullnameLowerCase: 1,
            },
          },
          { $sort: sortData || { expired: 1 } },
          { $skip: pageN > 0 ? (pageN - 1) * nPerPage : 0 },
          { $limit: nPerPage },
        ],
        totalData: [...pipeline, { $count: "count" }],
      },
    },
  ]);
  return ress;
};

export const getMessages = async (payload) => {
  const dbAdmin = await mongoClient.access("AdminDB", "admin_messages");
  const { filter, pageN, nPerPage } = payload;

  const [ress] = await dbAdmin.aggregate([
    {
      $facet: {
        data: [
          {
            $match: filter,
          },
          {
            $lookup: {
              from: "user_admin",
              localField: "createdBy",
              foreignField: "_id",
              as: "userCreated",
            },
          },
          {
            $project: {
              message: 1,
              subject: 1,
              createdAt: 1,
              read_status: 1,
              userCreated: { fullname: 1 },
            },
          },
          { $sort: { _id: 1 } },
          { $skip: pageN > 0 ? (pageN - 1) * nPerPage : 0 },
          { $limit: nPerPage },
        ],
        totalData: [
          {
            $match: filter,
          },
          { $count: "count" },
        ],
      },
    },
  ]);
  return ress;
};

export const getHistory = async (payload) => {
  const { collection, matchBy, groupBy, pageN, nPerPage } = payload;
  const dbHistory = await mongoClient.access("AdminDB", collection);

  const [ress] = await dbHistory.aggregate([
    {
      $facet: {
        data: [
          { $match: matchBy },
          { $unwind: { path: "$action" } },
          {
            $lookup: {
              from: "user_admin",
              let: { createdBy: "$createdBy" },
              pipeline: [
                { $match: { $expr: { $eq: ["$_id", "$$createdBy"] } } },
                { $project: { _id: 0, fullname: 1 } },
              ],
              as: "userCreated",
            },
          },
          {
            $unwind: {
              path: "$userCreated",
              preserveNullAndEmptyArrays: true,
            },
          },
          { $project: { action: 1, createdAt: 1, userCreated: 1 } },
          { $sort: { createdAt: -1 } },
          { $skip: pageN > 0 ? (pageN - 1) * nPerPage : 0 },
          { $limit: nPerPage },
        ],
        totalData: [
          { $match: matchBy },
          {
            $group: {
              _id: groupBy,
              count: { $sum: { $size: "$action" } },
            },
          },
          { $project: { _id: 0 } },
        ],
      },
    },
  ]);
  return ress;
};

// for assignee page
export const getClientsAssignee = async () => {
  const dbClient = await mongoClient.access("CORE_DB", "user");

  return dbClient.aggregate([
    { $match: { type: "owner" } },
    {
      $lookup: {
        from: "user_business",
        let: { user_id: "$_id" },
        pipeline: [
          { $match: { $expr: { $eq: ["$user_id", "$$user_id"] } } },
          { $project: { _id: "", name: 1 } },
        ],
        as: "userBusiness",
      },
    },
    {
      $addFields: {
        bussiness: {
          $first: "$userBusiness",
        },
      },
    },
    {
      $addFields: {
        fullname: {
          $concat: ["$fullname", " - ", "$bussiness.name"],
        },
      },
    },
    { $project: { fullname: 1 } },
  ]);
};

// for list pos version
export const getListPosVersion = async () => {
  const dbClient = await mongoClient.access("CORE_DB", "user_device");

  let posData = await dbClient.aggregate([
    {
      $match: {
        $and: [
          { outlet: { $ne: null } },
          { outlet: { $exists: true } },
          { posId: { $ne: null } },
          { posId: { $exists: true } },
          { pos_version: { $ne: null } },
          { pos_version: { $exists: true } },
        ],
      },
    },
    {
      $lookup: {
        from: "outlet",
        let: { outlet_id: "$outlet" },
        pipeline: [
          { $match: { $expr: { $eq: ["$_id", "$$outlet_id"] } } },
          { $project: { _id: "", name: 1 } },
        ],
        as: "outlet_data",
      },
    },
    {
      $lookup: {
        from: "pos_ids",
        let: { pos_id: "$posId" },
        pipeline: [
          { $match: { $expr: { $eq: ["$_id", "$$pos_id"] } } },
          { $project: { _id: "", name: 1 } },
        ],
        as: "pos_data",
      },
    },
    {
      $lookup: {
        from: "user_license",
        let: { licenseid: "$license" },
        pipeline: [
          { $match: { $expr: { $eq: ["$_id", "$$licenseid"] } } },
          {
            $lookup: {
              from: "user",
              let: { userid: "$user_id" },
              pipeline: [
                { $match: { $expr: { $eq: ["$_id", "$$userid"] } } },
                { $project: { _id: "", fullname: 1 } },
              ],
              as: "user_data",
            },
          },

          {
            $addFields: {
              fullname: {
                $first: "$user_data.fullname",
              },
            },
          },
          { $project: { _id: "", fullname: 1 } },
        ],
        as: "license_data",
      },
    },
    {
      $addFields: {
        outlet_name: {
          $first: "$outlet_data.name",
        },
      },
    },
    {
      $addFields: {
        pos_name: {
          $first: "$pos_data.name",
        },
      },
    },
    {
      $addFields: {
        fullname: {
          $first: "$license_data.fullname",
        },
      },
    },

    {
      $project: {
        outlet: 1,
        posId: 1,
        outlet_name: 1,
        pos_name: 1,
        fullname: 1,
        pos_version: 1,
        user_id: 1,
      },
    },
  ]);

  posData = [...posData].map((data) => {
    data.id = data._id.toString();
    delete data._id;
    data.user_id = data.user_id.toString();
    data.outlet = data.outlet.toString();
    data.posId = data.posId.toString();
    return data;
  });

  return posData;
};

export const getReportSales = async (payload) => {
  const { filterStartDate, filterEndDate, filterExcludeIds, nPerPage, pageN } =
    payload;
  const dbLicense = await mongoClient.access("CORE_DB", "user_license_payment");

  const pipeline = [
    {
      $match: {
        $and: [
          { status: "paid" },
          { type: "subscribed" },
          filterStartDate,
          filterEndDate,
          filterExcludeIds,
        ],
      },
    },
    {
      $lookup: {
        from: "user",
        let: { license: "$license" },
        pipeline: [
          {
            $match: {
              $expr: {
                $and: [
                  { $eq: ["$license", "$$license"] },
                  { $eq: ["$type", "owner"] },
                ],
              },
            },
          },
          {
            $project: {
              _id: 0,
              fullname: 1,
              phone: 1,
              license: 1,
            },
          },
        ],
        as: "user",
      },
    },
    {
      $unwind: {
        path: "$user",
        preserveNullAndEmptyArrays: false,
      },
    },
  ];

  const [ress] = await dbLicense.aggregate([
    {
      $facet: {
        data: [
          ...pipeline,
          {
            $group: {
              _id: "$user",
              total: { $sum: "$grandTotal" },
            },
          },
          {
            $project: {
              _id: 0,
              name: "$_id.fullname",
              phone: "$_id.phone",
              license: "$_id.license",
              total: 1,
            },
          },
          { $sort: { name: 1 } },
          { $skip: pageN > 0 ? (pageN - 1) * nPerPage : 0 },
          { $limit: nPerPage },
        ],
        totalData: [
          ...pipeline,
          {
            $group: {
              _id: null,
              grandTotal: { $sum: "$grandTotal" },
            },
          },
          {
            $project: {
              _id: 0,
              grandTotal: 1,
            },
          },
        ],
        countData: [
          ...pipeline,
          {
            $group: {
              _id: "$user",
            },
          },
          { $count: "count" },
        ],
      },
    },
  ]);
  return ress;
};

export const getReportSalesDetail = async (payload) => {
  const { filterLicense, filterStartDate, filterEndDate, nPerPage, pageN } =
    payload;
  const dbLicense = await mongoClient.access("CORE_DB", "user_license_payment");

  const pipeline = [
    {
      $match: {
        $and: [
          { status: "paid" },
          { type: "subscribed" },
          filterStartDate,
          filterEndDate,
          filterLicense,
        ],
      },
    },
  ];

  const [ress] = await dbLicense.aggregate([
    {
      $facet: {
        data: [
          ...pipeline,
          {
            $project: {
              _id: 1,
              license: 1,
              grandTotal: 1,
              invoiceNumber: 1,
              createdAt: 1,
            },
          },
          { $sort: { invoiceNumber: 1 } },
          { $skip: pageN > 0 ? (pageN - 1) * nPerPage : 0 },
          { $limit: nPerPage },
        ],
        countData: [...pipeline, { $count: "count" }],
      },
    },
  ]);
  return ress;
};

import { Button, CircularProgress } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";

const ReButtonSubmit = (props) => {
  const theme = useTheme();
  const { onSubmit, loading, disabled, id } = props;
  return (
    <>
      <Button
        {...props}
        id={id || "button-submit"}
        variant="contained"
        color="primary"
        type="submit"
        onClick={onSubmit}
        disabled={loading ? true : disabled}
        style={{
          float: "right",
          fontWeight: "bold",
          backgroundColor:
            disabled !== false || theme.customColor.background.backdrop,
          color: disabled !== false || theme.customColor.text.secondary,
        }}
      >
        Save
        {loading ? (
          <CircularProgress
            size={14}
            color="inherit"
            style={{ marginLeft: theme.spacing(1) }}
          />
        ) : (
          <></>
        )}
      </Button>
    </>
  );
};

export default ReButtonSubmit;

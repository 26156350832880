import { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import {
  Button,
  Collapse,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import MuiListItem from "@material-ui/core/ListItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { useTheme } from "@material-ui/styles";

import AssessmentIcon from "@material-ui/icons/Assessment";
import BuildIcon from "@material-ui/icons/Build";
import DevicesIcon from "@material-ui/icons/Devices";
import EmailIcon from "@material-ui/icons/Email";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import PermContactCalendarIcon from "@material-ui/icons/PermContactCalendar";
import ReorderIcon from "@material-ui/icons/Reorder";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import VpnLockIcon from "@material-ui/icons/VpnLock";
import SettingsIcon from "@material-ui/icons/Settings";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import PrintIcon from "@material-ui/icons/Print";

import {
  Dns,
  ExpandLess,
  ExpandMore,
  Extension,
  ExtensionTwoTone,
  Person,
} from "@material-ui/icons";
import { LSname } from "localstorage";
import { LogoutRealm } from "mongoservices/setup";
import preval from "preval.macro";
import store from "store/GeneralStore";
import { checkAclPermission } from "utils/functions";
import ReDialogConfirmation from "./ReDialogConfirmation";
const pjson = require("./../../package.json");

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  sideNav: {
    zIndex: 7,
    position: "fixed",
    left: "0px",
    top: "10px",
  },
  paperUp: {
    position: "absolute",
    left: "0px",
    width: "min-content",
  },
  paper: {
    marginLeft: "0px",
    width: "min-content",
  },
  listItem: {
    marginTop: "65px",
  },
  link: {
    color: "black",
    textDecoration: "none",
  },
  sideTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(-6),
  },
  submenu: {
    paddingLeft: theme.spacing(4),
  },
}));

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "rgba(0, 30, 107,1)",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
    "&$selected:hover": {
      backgroundColor: "rgba(0, 30, 107,1)",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  },
  selected: {},
})(MuiListItem);

const ModalDetailVersion = (props) => {
  const { onClose, open, detail } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      style={{
        minWidth: "min-content",
      }}
    >
      <DialogTitle>
        <Typography variant="body1">Detail Version</Typography>
      </DialogTitle>
      {/* FORM EDIT PASSWORD */}
      <DialogContent>
        {detail?.map((e) => (
          <Typography>{e}</Typography>
        ))}
      </DialogContent>
    </Dialog>
  );
};

const ReDrawer = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation().pathname;
  const history = useHistory();
  const [isDrawerOpened, setIsDrawerOpened] = useState(false);
  const tabletScreen = useMediaQuery("(max-width:767px)");
  const [selectedPath, setSelectedPath] = useState(location);
  const [open, setOpen] = useState(false);
  const [openDetailVersion, setOpenDetailVersion] = useState({
    open: false,
    detail: [],
  });
  const [openGeneral, setOpenGeneral] = useState(false);
  const [openTools, setOpenTools] = useState(false);
  const profile = JSON.parse(localStorage.getItem(LSname.userProfile) || null);
  const version = JSON.parse(
    localStorage.getItem(LSname.appServiceVersion) || null
  );

  useEffect(() => {
    setSelectedPath(location);
  }, [location]);

  useEffect(() => {
    if (selectedPath === "/country-management" || selectedPath === "/region")
      setOpenGeneral(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleDrawerStatus = () => {
    setIsDrawerOpened(true);
  };

  const closeDrawer = () => {
    setIsDrawerOpened(false);
  };

  const onLogout = async () => {
    setOpen(true);
  };

  const handleClose = async (value) => {
    if (value) {
      setOpen(false);
    } else {
      await LogoutRealm();
      localStorage.clear();
      store.resetState();
      history.push("/");
    }
  };

  return (
    <>
      <div
        className={classes.sideNav}
        style={{
          backgroundColor: tabletScreen
            ? theme.customColor.background.backdrop
            : theme.customColor.background.primaryOpacity,
        }}
      >
        <IconButton
          data-testid={"button-open-drawer"}
          onClick={toggleDrawerStatus}
        >
          {!isDrawerOpened ? (
            <ReorderIcon
              style={{
                color: tabletScreen
                  ? theme.customColor.text.secondary
                  : "transparent",
              }}
            />
          ) : null}
        </IconButton>
      </div>
      <Drawer
        variant={tabletScreen ? "temporary" : "permanent"}
        open={isDrawerOpened}
        onClose={closeDrawer}
        classes={{
          paper: tabletScreen ? classes.paper : classes.paperUp,
        }}
      >
        <div>
          <Typography className={classes.sideTitle} align="center">
            {profile?.fullname}
          </Typography>
          {/* <Link to="/dashboard" className={classes.link}>
            <List style={{ minWidth: "230px" }} className={classes.listItem}>
              <ListItem button selected={selectedPath === "/dashboard"}>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItem>
            </List>
          </Link> */}
          <Link to="/report" className={classes.link}>
            <List style={{ minWidth: "230px" }} className={classes.listItem}>
              <ListItem button selected={selectedPath.includes("/report")}>
                <ListItemIcon>
                  <AssessmentIcon />
                </ListItemIcon>
                <ListItemText primary="Report" />
              </ListItem>
            </List>
          </Link>
          {checkAclPermission(["LSA"]) && (
            <Link to="/user" className={classes.link}>
              <List>
                <ListItem button selected={selectedPath === "/user"}>
                  <ListItemIcon>
                    <Person />
                  </ListItemIcon>
                  <ListItemText primary="User" />
                </ListItem>
              </List>
            </Link>
          )}

          {checkAclPermission(["LSC"]) && (
            <Link
              to="/client"
              onClick={() => {
                localStorage.setItem(
                  LSname.dataFilterClient,
                  JSON.stringify({
                    fullname: "",
                    sortBy: "createdDate",
                    status: "all",
                    region: { value: "all", name: "all" },
                    page: 0,
                  })
                );
              }}
              className={classes.link}
            >
              <List>
                <ListItem button selected={selectedPath === "/client"}>
                  <ListItemIcon>
                    <PermContactCalendarIcon />
                  </ListItemIcon>
                  <ListItemText primary="Client" />
                </ListItem>
              </List>
            </Link>
          )}
          {(checkAclPermission(["SRC"]) || profile?.type === "superadmin") && (
            <Link to="/server-client" className={classes.link}>
              <List>
                <ListItem button selected={selectedPath === "/server-client"}>
                  <ListItemIcon>
                    <Dns />
                  </ListItemIcon>
                  <ListItemText primary="Server Client" />
                </ListItem>
              </List>
            </Link>
          )}
          {checkAclPermission(["LMW"]) && (
            <>
              <Link to="/license-master" className={classes.link}>
                <List>
                  <ListItem
                    button
                    selected={selectedPath === "/license-master"}
                  >
                    <ListItemIcon>
                      <DevicesIcon />
                    </ListItemIcon>
                    <ListItemText primary="License Master" />
                  </ListItem>
                </List>
              </Link>
              <Link to="/addons-master" className={classes.link}>
                <List>
                  <ListItem button selected={selectedPath === "/addons-master"}>
                    <ListItemIcon>
                      <Extension />
                    </ListItemIcon>
                    <ListItemText primary="Addons Master" />
                  </ListItem>
                </List>
              </Link>
            </>
          )}
          {checkAclPermission(["GEM"]) && (
            <>
              <ListItem
                button
                onClick={() => {
                  setOpenTools(!openTools);
                }}
              >
                <ListItemIcon>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Tools" />
                {openTools ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openTools} timeout="auto" unmountOnExit>
                <Link to="/history-transaction" className={classes.link}>
                  <List>
                    <ListItem
                      button
                      className={classes.submenu}
                      selected={selectedPath === "/history-transaction"}
                    >
                      <ListItemIcon>
                        <FindInPageIcon />
                      </ListItemIcon>
                      <ListItemText primary="History Transaction" />
                    </ListItem>
                  </List>
                </Link>
                <Link to="/print-history" className={classes.link}>
                  <List>
                    <ListItem
                      button
                      className={classes.submenu}
                      selected={selectedPath === "/print-history"}
                    >
                      <ListItemIcon>
                        <PrintIcon />
                      </ListItemIcon>
                      <ListItemText primary="Print History" />
                    </ListItem>
                  </List>
                </Link>
              </Collapse>
            </>
          )}
          <Link to="/messages" className={classes.link}>
            <List>
              <ListItem button selected={selectedPath === "/messages"}>
                <ListItemIcon>
                  <EmailIcon />
                </ListItemIcon>
                <ListItemText primary="Messages" />
              </ListItem>
            </List>
          </Link>

          {profile?.type === "superadmin" && (
            <>
              <Link to="/assignee" className={classes.link}>
                <List>
                  <ListItem button selected={selectedPath === "/assignee"}>
                    <ListItemIcon>
                      <SupervisorAccountIcon />
                    </ListItemIcon>
                    <ListItemText primary="Assignee" />
                  </ListItem>
                </List>
              </Link>

              <>
                <ListItem
                  button
                  onClick={() => {
                    setOpenGeneral(!openGeneral);
                  }}
                >
                  <ListItemIcon>
                    <BuildIcon />
                  </ListItemIcon>
                  <ListItemText primary="General" />
                  {openGeneral ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openGeneral} timeout="auto" unmountOnExit>
                  <Link to="/country-management" className={classes.link}>
                    <List>
                      <ListItem
                        button
                        className={classes.submenu}
                        selected={selectedPath === "/country-management"}
                      >
                        <ListItemIcon>
                          <VpnLockIcon />
                        </ListItemIcon>
                        <ListItemText primary="Country Management" />
                      </ListItem>
                    </List>
                  </Link>
                  <Link to="/region" className={classes.link}>
                    <List>
                      <ListItem
                        button
                        className={classes.submenu}
                        selected={selectedPath === "/region"}
                      >
                        <ListItemIcon>
                          <VpnLockIcon />
                        </ListItemIcon>
                        <ListItemText primary="Region" />
                      </ListItem>
                    </List>
                  </Link>
                </Collapse>
              </>
            </>
          )}
        </div>

        <Typography
          style={{ marginTop: theme.spacing(2), cursor: "pointer" }}
          variant="caption"
          align="center"
          onClick={() => {
            setOpenDetailVersion({ open: true, detail: pjson.notes_version });
          }}
        >
          Version : {pjson.version}
        </Typography>
        <Typography
          style={{ cursor: "pointer" }}
          variant="caption"
          align="center"
          onClick={() => {
            setOpenDetailVersion({ open: true, detail: version?.notes });
          }}
        >
          App Service : {version?.version}
        </Typography>
        <Typography variant="caption" align="center">
          Build Date:{" "}
          {preval`module.exports = new Date().toLocaleString('pt-PT').split(',')[0];`}
        </Typography>
        <Button
          variant="contained"
          onClick={onLogout}
          style={{
            margin: "20px auto",
            minWidth: "180px",
            backgroundColor: theme.palette.error.main,
            color: theme.customColor.text.secondary,
            fontWeight: "bold",
          }}
        >
          LOGOUT <ExitToAppIcon style={{ marginLeft: theme.spacing(1) }} />
        </Button>
        <ModalDetailVersion
          open={openDetailVersion.open}
          onClose={() => {
            setOpenDetailVersion({ open: false, detail: [] });
          }}
          detail={openDetailVersion.detail}
        />
        <ReDialogConfirmation
          idButtonSubmit="button-submit-logout"
          action="logout"
          open={open}
          onClose={handleClose}
        />
      </Drawer>
    </>
  );
};

export default ReDrawer;

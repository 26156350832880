import {
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme } from "@material-ui/styles";

import moment from "moment";
import ReAutoComplete from "components/ReAutoComplete";
import useLocalState from "hooks/useLocalState";
import { useEffect, useRef } from "react";
import { formContainerStyles } from "styles";
import mongoClient from "mongoservices/setup";
import { SentryLevel, sentSentryError } from "utils/sentry";
import { LSname } from "localstorage";

const getListYear = () => {
  const listYear = [];
  const start = 2023;
  const end = new Date().getFullYear();

  for (let i = start; i <= end; i++) {
    const data = i.toString();
    listYear.push({ value: i, name: data });
  }
  return listYear;
};

const getListMonth = () => {
  const listMonth = [];
  moment.months().forEach((item, index) => {
    listMonth.push({ value: index, name: item });
  });
  return listMonth;
};

const getExcludeInit = () => {
  const getExclude = JSON.parse(localStorage.getItem(LSname.dataExclude));
  if (getExclude) {
    return getExclude;
  }
  return [{ _id: "", fullname: "" }];
};

const SalesFilter = ({ onBack, handleSetFilter, filterData }) => {
  const classes = formContainerStyles();
  const theme = useTheme();
  const mobileScreen = useMediaQuery("(max-width:600px)");
  const _refYear = useRef();
  const _refMonth = useRef();
  const _refExclude = useRef();
  const { getLocalState, updateLocalState } = useLocalState({
    year: filterData?.year ? filterData.year : null,
    listYear: getListYear(),
    month: filterData?.month ? filterData.month : null,
    listMonth: getListMonth(),
    disableMonth: true,

    exclude: getExcludeInit(),
    listExclude: [],
    fullDataExclude: [],
    disableAddExclude: true,
  });

  const handleAddExclude = () => {
    getLocalState().exclude.push({ _id: "", fullname: "" });
    updateLocalState({ exclude: [...getLocalState().exclude] });
  };

  const handleRemoveExclude = (id) => {
    const dataExclude = getLocalState().exclude.filter(
      (item) => item._id.toString() !== id
    );
    updateLocalState({ exclude: dataExclude });
  };

  const construcDataOption = (data) => {
    return data.map(({ license: value, fullname: name, ...rest }) => ({
      value,
      name,
      ...rest,
    }));
  };

  const fetchClient = async () => {
    try {
      const dbClient = await mongoClient.access("CORE_DB", "user");
      const filterClient = { type: "owner" };

      const clients = await dbClient.find(filterClient, {
        projection: { fullname: 1, license: 1 },
      });

      const listData = construcDataOption(clients);
      updateLocalState({ listExclude: listData, fullDataExclude: listData });
    } catch (error) {
      sentSentryError(error, SentryLevel.Error);
      console.error(error);
    }
  };

  const isAllowAddExclude = () => {
    const checkExclude = () => {
      const checkData = getLocalState().exclude.filter(
        (x) => x._id.length <= 0
      );
      return checkData.length > 0;
    };

    const newOption = getLocalState().fullDataExclude.filter(
      (v) =>
        !getLocalState().exclude.find(
          (x) => x._id.toString() === v.value.toString()
        )
    );

    updateLocalState({
      listExclude: [...newOption],
      disableAddExclude: checkExclude(),
    });
  };

  const handleSubmit = () => {
    const exclude = JSON.stringify(getLocalState().exclude);
    localStorage.setItem(LSname.dataExclude, exclude);

    handleSetFilter({
      year: _refYear.current?.getValueOption(),
      month: _refMonth.current?.getValueOption(),
      exclude: getLocalState().exclude,
    });
    onBack();
  };

  const handleReset = () => {
    localStorage.removeItem(LSname.dataExclude);

    handleSetFilter({
      year: null,
      month: null,
      exclude: [{ _id: "", fullname: "" }],
    });
    onBack();
  };

  useEffect(() => {
    fetchClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    isAllowAddExclude();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getLocalState().exclude]);

  return (
    <div className={mobileScreen ? classes.list : classes.listWider}>
      <Grid container direction="column" className={classes.container}>
        {/* TITLE & BACK BUTTON */}
        <Grid item>
          <Typography
            variant="h5"
            style={{
              fontWeight: "bold",
              verticalAlign: "middle",
              display: "inline-flex",
            }}
          >
            <ArrowBackIosIcon
              id="arrow-back-filter-expired"
              fontSize="medium"
              color="primary"
              className={classes.backButton}
              onClick={() => {
                onBack();
              }}
            />
            Filter
          </Typography>
        </Grid>

        {/* FILTER DROPDOWN YEAR & MONTH */}
        <ReAutoComplete
          ref={_refYear}
          id="select-year"
          label="Year"
          items={getLocalState().listYear}
          defaultValue={getLocalState().year}
          onFinish={(_, valOption) => {
            if (valOption?.value) {
              updateLocalState({ disableMonth: false });
            } else {
              updateLocalState({ disableMonth: true });
              _refMonth.current?.setValueOption([]);
            }
          }}
        />
        <ReAutoComplete
          ref={_refMonth}
          id="select-month"
          label="Month"
          disabled={getLocalState().disableMonth}
          items={getLocalState().listMonth}
          defaultValue={getLocalState().month}
        />

        {/* FILTER EXCLUDE CLIENT */}
        <Grid item>
          <Typography
            style={{
              fontWeight: "bold",
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(-2),
            }}
          >
            Exclude :
          </Typography>
          {getLocalState().exclude.length > 0
            ? getLocalState().exclude.map((item, idx) => (
                <Grid container key={item._id.toString()}>
                  <Grid item xs={10}>
                    <ReAutoComplete
                      ref={_refExclude}
                      id={`input-exclude-${idx}`}
                      required
                      items={getLocalState().listExclude || []}
                      label="Client"
                      defaultValue={{ name: item.fullname, value: item._id }}
                      onFinish={(value, valueOption) => {
                        const dataExclude = [...getLocalState().exclude];
                        dataExclude[idx] = {
                          fullname: valueOption?.name || "",
                          _id: value || "",
                        };
                        updateLocalState({ exclude: dataExclude });
                      }}
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      onClick={() => {
                        handleRemoveExclude(item._id.toString());
                      }}
                      className={classes.IconButton}
                    >
                      <CloseIcon
                        className={classes.addCloseButton}
                        fontSize="large"
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              ))
            : null}
          <Button
            id={"add-exclude"}
            data-testid={"add-exclude"}
            onClick={() => {
              handleAddExclude();
            }}
            disabled={getLocalState().disableAddExclude}
            variant="contained"
            color="secondary"
            style={{ marginTop: theme.spacing(2) }}
            size="small"
            fullWidth
          >
            <AddIcon fontSize="small" />
            Exclude
          </Button>
        </Grid>

        {/* RESET BUTTON & SAVE BUTTON */}
        <Grid
          container
          direction="row"
          style={{ marginTop: theme.spacing(3) }}
          justifyContent="space-between"
        >
          <Button
            variant="contained"
            data-testid="button-reset"
            style={{
              fontWeight: "bold",
              backgroundColor: theme.palette.secondary.main,
              color: theme.customColor.text.secondary,
            }}
            onClick={() => {
              handleReset();
            }}
          >
            Reset
          </Button>
          <Button
            id="button-submit-filter-sales"
            data-testid="button-submit-filter"
            variant="contained"
            color="primary"
            type="submit"
            style={{
              fontWeight: "bold",
              backgroundColor: theme.customColor.background.backdrop,
              color: theme.customColor.text.secondary,
            }}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default SalesFilter;

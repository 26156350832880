import { useEffect, useState } from "react";

import {
    Grid,
    InputAdornment,
    List,
    TextField,
    Typography,
    useMediaQuery,
    useTheme,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { makeStyles } from "@material-ui/styles";

import BSON from "bson";
import mongoClient from "mongoservices/setup";

import ReButtonSubmit from "components/ReButtonSubmit";
import ReLoadingData from "components/ReLoadingData";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { SentryLevel, sentSentryError } from "utils/sentry";

const useStyles = makeStyles((theme) => ({
  list: {
    maxWidth: "100%",
  },
  listWider: {
    maxWidth: "400px",
  },
  container: {
    padding: theme.spacing(3),
    overflowX: "hidden",
  },
  backButton: {
    marginRight: theme.spacing(1),
    stroke: theme.customColor.background.backdrop,
    strokeWidth: 2,
  },
  fontStyle: {
    fontSize: theme.spacing(2),
    fontFamily: "Poppins-Medium",
    // marginBottom: theme.spacing(2),
  },
  inputLabel: {
    backgroundColor: theme.customColor.background.secondary,
    color: "black",
    fontFamily: "Poppins-Medium",
  },
  labelAsterisk: {
    color: "red",
  },
  inputNonDisable: {
    border: `2px solid ${theme.palette.primary.main} !important`,
  },
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  textBackButton: {
    fontWeight: "bold",
    verticalAlign: "middle",
    display: "inline-flex",
    // marginBottom: theme.spacing(2),
  },
}));

const EditCountry = ({ onBack, selectedData, onFetchData }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const mobileScreen = useMediaQuery("(max-width:600px)");
  const [loadingButton, setLoadingButton] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [taxes, setTaxes] = useState(selectedData.tax);
  const [generalConfig, setGeneralConfig] = useState(
    selectedData?.generalConfig || []
  );

  const handleSubmit = async () => {
    setLoadingButton(true);
    try {
      const dbCountry = await mongoClient.access(
        "CORE_DB",
        "master_reg_country"
      );
      const dbLicenseMaster = await mongoClient.access(
        "CORE_DB",
        "master_license"
      );
      const dbLicensePriceLevel = await mongoClient.access(
        "CORE_DB",
        "master_license_price_level"
      );

      // update tax di coll master_reg_country
      await dbCountry.updateOne(
        { _id: selectedData._id },
        {
          $set: {
            tax: BSON.Long.fromNumber(taxes),
            generalConfig: generalConfig,
          },
          $currentDate: {
            updatedAt: { $type: "date" },
          },
        }
      );

      // get all master_license with country_id = selected.code
      let findLicense = await dbLicenseMaster.find(
        {
          country_id: selectedData._id,
        },
        { projection: { auto_tax: 1, priceLevel: 1 } }
      );

      // get all priceLevel data in master_license with country_id = selected._id and auto_tax = true
      const priceLevel_ids = findLicense.reduce((prev, curr) => {
        if (curr?.priceLevel?.length > 0 && curr?.auto_tax === true) {
          prev.push(...curr.priceLevel);
        }
        return prev;
      }, []);

      // update tax di coll. master_license_price_level
      await dbLicensePriceLevel.updateMany(
        { _id: { $in: priceLevel_ids } },
        {
          $set: {
            tax: BSON.Double(Number(taxes)),
          },
          $currentDate: {
            updatedAt: { $type: "date" },
          },
        }
      );

      onFetchData();
      onBack();
    } catch (e) {
      sentSentryError(e, SentryLevel.Error, {
        category: "action",
        message: "Edit data country",
        data: {},
        level: "info",
      });
      console.error(e);
    }
    setLoadingButton(false);
  };

  const isAllowSave = () => {
    let getTaxes = Number(taxes) || 0;
    const currentGeneralConf = JSON.stringify(generalConfig);
    const prevGeneralConf = JSON.stringify(selectedData?.generalConfig || []);

    const allow =
      getTaxes !== selectedData.tax || currentGeneralConf !== prevGeneralConf;

    setDisableSubmit(!allow);
  };

  useEffect(() => {
    isAllowSave();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [taxes, generalConfig]);

  useEffect(() => {
    return history.listen(() => {
      if (history.action === "POP") {
        onBack();
        history.push("/country-management");
      }
    });
  }, []);

  return (
    <div className={mobileScreen ? classes.list : classes.listWider}>
      {!selectedData ? (
        <ReLoadingData />
      ) : (
        <Grid container direction="column" className={classes.container}>
          <Grid item>
            <Typography variant="h5" className={classes.textBackButton}>
              <ArrowBackIosIcon
                id="arrow-back-edit-country"
                fontSize="medium"
                color="primary"
                className={classes.backButton}
                onClick={() => {
                  onBack();
                }}
              />
              EDIT COUNTRY
            </Typography>
          </Grid>
          <br />

          {selectedData && (
            <>
              <Typography className={classes.fontStyle}>
                {`Country : ${selectedData.name}`}
              </Typography>
              <br />

              <TextField
                id="input-tax"
                type="number"
                variant="outlined"
                value={taxes}
                required
                label="Tax"
                size="medium"
                className={classes.input}
                onChange={(e) => {
                  if (
                    e.target.value.toString().length > 2 ||
                    e.target.value < 0
                  )
                    return;
                  setTaxes(e.target.value);
                }}
                InputLabelProps={{
                  className: classes.inputLabel,
                  formlabelclasses: {
                    asterisk: classes.labelAsterisk,
                  },
                }}
                InputProps={{
                  className: classes.inputLabel,
                  endAdornment: <InputAdornment>%</InputAdornment>,
                  classes: {
                    notchedOutline: classes.inputNonDisable,
                  },
                }}
              ></TextField>

              {generalConfig &&
                generalConfig.map(({ key, value, label }, idx) => (
                  <TextField
                    style={{ marginTop: theme.spacing(2) }}
                    key={key}
                    name={key}
                    type="number"
                    variant="outlined"
                    value={value}
                    label={label}
                    size="medium"
                    className={classes.input}
                    onChange={(e) => {
                      const dataGeneral = [...generalConfig];
                      dataGeneral[idx] = {
                        key,
                        value: parseInt(e.target.value),
                        label,
                      };
                      setGeneralConfig(dataGeneral);
                    }}
                  ></TextField>
                ))}
            </>
          )}
          <br />

          <List>
            <ReButtonSubmit
              id="button-submit-edit-country"
              data-testid="button-submit-edit-country"
              loading={loadingButton}
              disabled={disableSubmit}
              onSubmit={() => {
                handleSubmit();
              }}
            />
          </List>
        </Grid>
      )}
    </div>
  );
};

export default EditCountry;

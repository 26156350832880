import { makeStyles } from "@material-ui/styles";
import BackgroundLogo from "assets/images/logo/grande.svg";
import { forwardRef, useImperativeHandle, useState } from "react";

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: theme.customColor.background.primary,
  },
  container: {
    position: "relative",
    width: "100px",
    height: "100px",
  },
  shapeStyle: {
    borderRadius: "50%",
    width: "20px",
    height: "20px",
    margin: "0 auto",
    display: "inline-block",
  },
  dot1: {
    position: "absolute",
    left: 0,
    background: theme.customColor.background.backdrop,
    animation: `$dots 1s ease-in infinite`,
    marginLeft: "12px",
  },
  dot2: {
    background: theme.palette.secondary.main,
    marginLeft: "22px",
    animation: `$dots 1s 0.25s ease-in infinite`,
  },
  dot3: {
    background: theme.palette.error.main,
    marginLeft: "5px",
    animation: `$dots 1s 0.5s ease-in infinite`,
  },
  "@keyframes dots": {
    "0%": {
      transform: "translateY(100px)",
      opacity: 1,
    },
    "50%": {
      transform: "translateY(80px)",
      opacity: "0.7",
    },
    "60%": {
      transform: "translateY(80px)",
    },
    "100%": {
      transform: "translateY(100px)",
      opacity: 1,
    },
  },
}));

const styles = {
  paperContainer: {
    backgroundImage: "url(" + BackgroundLogo + ")",
    backgroundRepeat: "no-repeat",
  },
};

const ReCircularProgress = forwardRef((props, ref) => {
  const classes = useStyles();
  const [isShow, setIsShow] = useState(true);

  useImperativeHandle(ref, () => ({
    setVisibility(value) {
      setIsShow(value);
    },
  }));

  return (
    <>
      {isShow ? (
        <div className={classes.root}>
          <div className={classes.container} style={styles.paperContainer}>
            <div className={`${classes.shapeStyle} ${classes.dot1}`}></div>
            <div className={`${classes.shapeStyle} ${classes.dot2}`}></div>
            <div className={`${classes.shapeStyle} ${classes.dot3}`}></div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
});

export default ReCircularProgress;

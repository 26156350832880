import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { LSname } from "localstorage";
import mongoClient, { Call } from "mongoservices/setup";
import { BSON } from "realm-web";
import requestNotifPermission from "utils/requestNotifPermission";
import { SentryLevel, sentSentryError } from "utils/sentry";

const CONFIG = {
  FCM_CONFIG: "FCM_CONFIG",
  FCM_SERVER_KEY: "FCM_SERVER_KEY",
  FCM_VAPID_KEY: "FCM_VAPID_KEY",
};

const TOPIC = {
  ALL: "all",
};

class FCM {
  static _instance;
  constructor() {
    this.firebaseConfig = null;
    this.clientNotificationToken = null;
    this.fcmMessaging = null;

    // SETTING SINGLETON
    if (FCM._instance) {
      return FCM._instance;
    }
    FCM._instance = this;
  }

  async configInit() {
    let permission = await requestNotifPermission();
    if (permission) {
      const response = await Call("ADMIN", "clientFcmConfig");
      this.firebaseConfig = response;
      return response;
    }
  }

  async subscribeFCMTopic() {
    const localConfig = this.firebaseConfig || (await this.configInit());
    const serverKey = localConfig[CONFIG.FCM_SERVER_KEY];

    try {
      let googleSubscribe = await fetch(
        `https://iid.googleapis.com/iid/v1/${this.clientNotificationToken}/rel/topics/${TOPIC.ALL}`,
        {
          method: "POST",
          headers: new Headers({
            Authorization: serverKey,
          }),
        }
      );
      if (googleSubscribe.status < 200 || googleSubscribe.status >= 400) {
        console.log(googleSubscribe.status, googleSubscribe);
      } else {
        console.log(`SUCCESS LISTEN NOTIF FROM "${TOPIC.ALL}" `);
      }
    } catch (err) {
      sentSentryError(err, SentryLevel.Error);
    }
  }

  async initiateFCMConfig(isPermission) {
    const realmNotifToken = await this.checkRealmFCMToken();

    if (
      realmNotifToken &&
      !FCM._instance.firebaseConfig &&
      isPermission &&
      "PushManager" in window
    ) {
      const localConfig = this.firebaseConfig || (await this.configInit());
      const configKey = JSON.parse(localConfig[CONFIG.FCM_CONFIG]);
      const vapidKey = localConfig[CONFIG.FCM_VAPID_KEY];
      const app = initializeApp(configKey);
      const messaging = getMessaging(app);
      this.fcmMessaging = messaging;
      const tokenRegister = await getToken(messaging, {
        vapidKey: vapidKey,
      });

      if (tokenRegister) {
        this.clientNotificationToken = tokenRegister;
        if (realmNotifToken === "new" || realmNotifToken[0] !== tokenRegister) {
          console.log("new token or diff");
          this.upsertRealmFCMToken(tokenRegister);
        } else {
          this.subscribeFCMTopic();
        }
      } else {
        console.log("Can not get token");
      }
      try {
        new Promise((resolve) => {
          onMessage(messaging, (payload) => {
            console.log("payload > ", payload);
            resolve(payload);
          });
        });
      } catch (error) {
        sentSentryError(error, SentryLevel.Error);
      }
    }
  }

  async checkRealmFCMToken() {
    const notifToken = localStorage.getItem(LSname.notifToken);
    if (notifToken) {
      return notifToken;
    }
    // Check ACL apakah ada GEM
    // jika ada cek apakah notification token sudah ada di collection
    let userProfile = localStorage.getItem(LSname.userProfile);
    userProfile = userProfile ? JSON.parse(userProfile) : null;
    if (userProfile && userProfile?.acl?.includes("GEM")) {
      const dbUser = await mongoClient.access("AdminDB", "user_admin");
      const findUser = await dbUser.findOne(
        {
          _id: BSON.ObjectId(userProfile?.id),
        },
        { projection: { notification_token: 1 } }
      );
      if (findUser?.notification_token) {
        localStorage.setItem(
          LSname.notifToken,
          JSON.stringify(findUser?.notification_token)
        );
      }
      return findUser?.notification_token || "new";
    }
    return null;
  }

  async upsertRealmFCMToken(notifToken) {
    let userProfile = localStorage.getItem(LSname.userProfile);
    userProfile = userProfile ? JSON.parse(userProfile) : null;
    const dbAdmin = await mongoClient.access("AdminDB", "user_admin");
    const checkIsReadySameToken = await dbAdmin.count({
      notification_token: { $in: [notifToken] },
    });

    if (userProfile && checkIsReadySameToken <= 0) {
      try {
        const result = await dbAdmin.updateOne(
          {
            _id: BSON.ObjectId(userProfile?.id),
          },
          { $set: { notification_token: [notifToken] } },
          { upsert: true }
        );

        return result;
      } catch (e) {
        sentSentryError(e, SentryLevel.Error, {
          category: "action",
          message: "upsertRealmFCMToken in user_admin",
          data: { $set: { notification_token: [notifToken] } },
          level: "info",
        });
        console.error(e);
      }
    }
  }
}

export { FCM };

import React, { useEffect, useSyncExternalStore } from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import requestNotifPermission from "utils/requestNotifPermission";
import { FCM } from "firebase";
import ReHeaderTitle from "components/ReHeaderTitle";
import ReDrawer from "components/ReDrawer";
import ReLoadingData from "components/ReLoadingData";
import store from "store/GeneralStore";

const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    backgroundColor: theme.customColor.background.primaryOpacity,
    display: "flex",
    flexWrap: "wrap",
  },
  generalContainer: {
    paddingTop: theme.spacing(10),
    position: "relative",
    maxWidth: "100%",
    overflowX: "hidden",
    backgroundColor: theme.customColor.background.secondary,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
}));

const useStore = (selector = (state) => state) =>
  useSyncExternalStore(store.subscribe, () => selector(store.getState()));

const ListenLoading = () => (
  <>{useStore((state) => state["loading"]) && <ReLoadingData />}</>
);

const LayoutAdminWrapper = ({
  children,
  title,
  onMaxWidth,
  disableMaxHeight,
}) => {
  const classes = useStyles();
  const mobileScreenUp = useMediaQuery("(min-width:600px)");

  const listenNotif = async () => {
    const isPermission = await requestNotifPermission();
    new FCM().initiateFCMConfig(isPermission);
  };

  useEffect(() => {
    listenNotif();
  }, []);

  return (
    <div
      className={classes.layoutContainer}
      style={{
        justifyContent: mobileScreenUp ? "center" : "flex-start",
        maxHeight: mobileScreenUp ? "min-content" : "fit-content",
      }}
    >
      <ReHeaderTitle title={title} />
      {!mobileScreenUp && <ReDrawer />}
      <Box
        boxShadow={3}
        className={classes.generalContainer}
        style={{
          maxHeight: disableMaxHeight ? null : "calc(100vh - 80px)",
          minWidth: mobileScreenUp ? "64%" : "100%",
          [onMaxWidth ? "maxWidth" : "width"]: mobileScreenUp ? "64%" : "100%",
          paddingLeft: mobileScreenUp ? "250px" : "0px",
          paddingRight: mobileScreenUp ? "20px" : "0px",
          overflowY: mobileScreenUp ? "hidden" : "auto",
          zIndex: 4,
        }}
      >
        {mobileScreenUp && <ReDrawer />}
        <>{children}</>

        <ListenLoading />
      </Box>
    </div>
  );
};

export default LayoutAdminWrapper;

import { ThemeProvider } from "@material-ui/core/styles";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { LSname } from "localstorage";
import { fetchNotif, watchNotif } from "mongoservices/setup";
import { useEffect } from "react";
import store from "store/GeneralStore";
import { SentryInfo, SentryLevel } from "utils/sentry";
import "./App.css";
import Routes from "./Routes";
import ThemeStyles from "./ThemeStyles";
const pjson = require("./../package.json");

const inAttributte = (target, labelName) => {
  return target ? `, ${labelName}: ${target.toUpperCase()}` : "";
};

function App() {
  let prefix = "admin";
  prefix = prefix.concat(`_${process.env.REACT_APP_SENTRY_ENV.toUpperCase()}`);
  const currentVersion = pjson.version;

  useEffect(() => {
    const profile = JSON.parse(
      localStorage.getItem(LSname.userProfile) || null
    );
    if (profile) {
      fetchNotif();
      watchNotif();
      store.setState({ acl: profile.acl });
    }

    document.title = `${process.env.REACT_APP_TITLE} - ${document.title}`;
    if (
      !["LOCALDEV", "LOCALPROD", "LOCALSTAGING", "DEVELOPMENTADMIN"].includes(
        process.env.REACT_APP_ENV
      )
    ) {
      window.console.log = () => {};
      window.console.debug = () => {};
      window.console.info = () => {};
      window.console.warn = () => {};
      window.console.error = () => {};

      Sentry.init({
        dsn: "https://58f01182446c4db09c03652da0509e4a@sentry.mgc.pw/8",
        integrations: [
          new BrowserTracing(),
          new Sentry.Integrations.Breadcrumbs({
            console: false,
            xhr: true,
            location: true,
          }),
        ],

        release: `${prefix}@${currentVersion}`,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        environment: process.env.REACT_APP_SENTRY_ENV || "LOCAL",
        initialScope: (scope) => {
          scope.setTags({ errorType: "UNHANDLED ERROR" });
          scope.setLevel(SentryLevel.Fatal);
          scope.setTags(SentryInfo().tags);
          scope.setUser(SentryInfo().user);

          return scope;
        },
        // custom breadcrumb
        beforeBreadcrumb(breadcrumb, hint) {
          if (breadcrumb.category === "ui.click") {
            const { target } = hint.event;

            const attributte = {
              innerText: inAttributte(target?.innerText, "innerText"),
              id: inAttributte(target?.id, "id"),
              ariaLabel: inAttributte(target?.ariaLabel, "ariaLabel"),
              name: inAttributte(target?.name, "name"),
              textContent: inAttributte(target?.textContent, "textContent"),
            };
            const message = `tagName : ${target?.tagName}${attributte.innerText}${attributte.id}${attributte.ariaLabel}${attributte.name}`;

            if (
              target?.tagName === "path" ||
              message === "tagName : svg" ||
              message === "tagName : DIV"
            )
              return breadcrumb;

            breadcrumb.message = message;
          }
          return breadcrumb;
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={ThemeStyles}>
        <Routes />
      </ThemeProvider>
    </div>
  );
}

export default App;
